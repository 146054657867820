

.chat__card {
    display: grid;
    place-items: center;
    height: 100vh;
  }
  
  .chat__card__body {
    display: flex;
    height: 100%;
    width: 100%;
  }
  .default__image{
    /* background-image: url('../../assets/images/bg/message-bg.png'); */
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    flex: 0.65;
    justify-content: center;
    align-items: center;
    height:100vh;
  }
  