/* Centering the card */
.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  /* Card styling */
  .reset-password-card {
    background: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 350px;
    text-align: center;
  }
  
  /* Title */
  .reset-password-title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  /* Form styling */
  .reset-password-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  /* Input field */
  .form-group {
    text-align: left;
  }
  
  .form-label {
    font-size: 14px;
    font-weight: 500;
    color: #333;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .form-input:focus {
    border-color: #f4b400;
    outline: none;
  }
  
  /* Error message */
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  /* Buttons */
  .submit-button {
    width: 100%;
    background-color: #f4b400;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .submit-button:hover {
    background-color: #d89c00;
  }