
.c-loader {
  margin-top: 3rem;
  text-align: center;
}


.c-loader__item {
  display: inline-block;
  width: 1rem; /*~24px*/
  height: 1rem;
  margin-right: 1rem;
  border-radius: 50%;
  background: #001c63;
  animation: pulse 2.3s 0s infinite;
}

.c-loader__item:nth-child(2) {
  animation-delay: 0.46s;
  background: #d70b52;
}


.c-loader__item:nth-child(3) {
  animation-delay: 0.92s;
  background: #fa9b00;
}


.c-loader__item:nth-child(4) {
  animation-delay: 1.38s;
  background: #16fcf7;
}


.c-loader__item:nth-child(5) {
  animation-delay: 1.84s;
  background: #ed776e;
}

/*=================*\
      KeyFrames
/*=================*/
@keyframes pulse {

  0% {
    transform: scale(1.5);
  }
  
  20% {
    transform: scale(1);
  }
}