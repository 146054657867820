/* 
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,400i,700,900');
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,400i,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700,900');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:200,300,400,400i,500,500i,600,600i,700,700i');
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i'); */

span.tx-gradinet {
	-webkit-text-fill-color: transparent;
	background-image: -webkit-radial-gradient(circle, #051937, #004d7a, #008793, #00bf72, #a8eb12);
}

a {
	color: #101c29;
	font-weight: 500;
}

a:hover {
	color: #001f5f;
	/* text-decoration: underline !important; */
}

p {
	color: #868e96;
}

body {
	margin: 0;
	/* font-family: "IBM Plex Sans", sans-serif; */
	font-size: 15px !important;
	line-height: 1.5;
	color: #8392a5;
	text-align: left;
	background-color: #fff;
}

.tx-dark {
	color: #101c29;
}

.tx-lato {
	font-family: 'Lato', sans-serif;
}

.tx-rubik {
	font-family: 'Rubik', sans-serif;
}

.tx-roboto {
	font-family: 'Roboto', sans-serif;
}

.tx-open-sans {
	font-family: 'Open Sans', sans-serif;
}

.tx-ibm {
	font-family: 'IBM Plex Sans', sans-serif;
}

.tx-poppins {
	font-family: 'Poppins', sans-serif;
}

.bg-facebook {
	color: #fff;
	background: #3b5998 !important;
}

.bg-twitter {
	color: #fff;
	background: #00aced !important;
}

.bg-instagram {
	color: #fff;
	background: #da2e76 !important;
}

.bg-linkedin {
	color: #fff;
	background: #4875b4 !important;
}

.bg-google-plus {
	color: #fff;
	background: #d34836 !important;
}

.bg-dribbble {
	color: #fff;
	background: #ea4c8a !important;
}

.bg-flickr {
	color: #fff;
	background: #ff0084 !important;
}

.bg-google {
	color: #fff;
	background: #fb4104 !important;
}

.bg-youtube {
	color: #fff;
	background: #f70000 !important;
}

.bg-pinterest {
	color: #fff;
	background: #c51f26 !important;
}

.tx-facebook {
	color: #3b5998;
}

.tx-twitter {
	color: #00aced;
}

.tx-instagram {
	color: #da2e76;
}

.tx-linkedin {
	color: #4875b4;
}

.tx-google-plus {
	color: #d34836;
}

.tx-dribbble {
	color: #ea4c8a;
}

.tx-flickr {
	color: #ff0084;
}

.tx-google {
	color: #fb4104;
}

.tx-youtube {
	color: #f70000;
}

.tx-pinterest {
	color: #c51f26;
}

.bg-gray {
	background: #e0e7fd;
}

.bg-soft-primary {
	background-color: rgba(93, 120, 255, 0.15) !important;
	color: #001f5f !important;
}

.bg-soft-secondary {
	background-color: rgba(143, 147, 148, 0.15) !important;
	color: #7d7f80 !important;
}

.bg-soft-warning {
	background-color: rgba(243, 170, 18, 0.15) !important;
	color: hsl(41, 90%, 51%) !important;
}

.bg-soft-success {
	background-color: rgba(43, 173, 13, 0.15) !important;
	color: rgb(43, 173, 13) !important;
}

.bg-soft-danger {
	background-color: rgba(244, 67, 54, 0.15) !important;
	color: rgb(255, 43, 43) !important;
}

.bg-soft-teal {
	background-color: rgba(74, 199, 236, 0.15) !important;
	color: #4ac7ec !important;
}

.bg-soft-pink {
	background-color: rgba(255, 93, 160, 0.15) !important;
	color: #ff5da0 !important;
}

.custom-text-primary {
	color: #001f5f;
}

.custom-text-primary:hover {
	color: #000000;
	text-decoration: none;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.btn-custom-primary {
	color: #fff;
	background-color: #001f5f;
	border-color: #001f5f;
	text-transform: capitalize;
}

.btn-custom-primary:hover {
	color: #fff;
	text-decoration: none;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.ht-38 {
	height: 38px;
}

.card-icon-primary {
	color: #001f5f !important;
	background: rgba(5, 88, 183, 0.1) !important;
}

.card-icon-danger {
	color: #dc3545 !important;
	background: rgba(220, 53, 69, 0.1) !important;
}

.card-icon-success {
	color: #28a745 !important;
	background: rgba(40, 167, 69, 0.1) !important;
}

.card-icon-warning {
	color: #ffc107 !important;
	background: rgba(234, 147, 12, 0.1) !important;
}

.card-icon-teal {
	color: #17a2b8 !important;
	background: rgba(23, 162, 184, 0.1) !important;
}

.page-container {
	padding-right: 0;
	padding-left: 0;
	min-height: 100%;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

.page-container:not(.container) {
	width: 100%;
}

.page-content {
	min-height: 60px;
	vertical-align: bottom;
	width: 100%;
	float: right;
}

_::-webkit-full-page-media,
_:future,
:root .page-content {
	vertical-align: top;
}

.page-inner {
	position: relative;
	padding: 0 25px 0;
	width: 100%;
	height: 100%;
}

.page-inner.full-page {
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
}

.page-header-fixed .page-inner {
	margin-top: 60px;
}

_::-webkit-full-page-media,
_::future,
:root .page-inner {
	padding-bottom: 0;
}

.main-wrapper {
	overflow: hidden;
	display: block;
}

.page-footer {
	background: #f9f9fc;
	border: 1px solid rgba(230, 230, 230, 0.7);
	border-left: none;
}

/**********************************************************************
***********************************************************************
### Header CSS
*********************************************************************
*********************************************************************/

.page-header {
	padding-bottom: 0;
	margin: 0;
	border-bottom: none;
	height: 60px;
	position: sticky;
	top: 0;
	z-index: 99;
	background: #ffffff;
	border-bottom: 1px solid rgba(230, 230, 230, 0.7);
}

.page-header,
.page-sidebar {
	-webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
	box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
}

.page-header-fixed .page-header {
	position: fixed;
	z-index: 99;
	left: 250px;
	right: 0;
	top: 0;
}

.page-header-fixed .page-container.container .page-header {
	padding-right: 15px;
	padding-left: 195px;
	left: 0;
	right: 0;
	margin: auto;
}

.page-header-fixed.page-sidebar-collapsed .page-container.container .page-header {
	padding-right: 15px;
	padding-left: 75px;
	left: 0;
	right: 0;
	margin: auto;
}

.page-sidebar-collapsed.page-header-fixed .page-header {
	left: 60px;
}

.page-sidebar-fixed .page-header {
	z-index: 41;
}

.page-header .navbar-default {
	background-color: #ffffff;
	margin-bottom: 0;
	height: 60px;
	border-radius: 0;
	display: block;
	border-bottom: 1px solid rgba(230, 230, 230, 0.7);
}

.page-header .navbar-default .container-fluid,
.page-header .navbar-default .navbar-collapse {
	height: 60px !important;
}

.page-header .navbar-default .navbar-brand i.fixed-sidebar-button-active {
	background: #fff;
}

.navbar-brand {
	float: left;
	height: 50px;
	padding: 8px 0 0px 0px;
	font-size: 14px;
	line-height: 20px;
}

.page-header .navbar-nav > li.v-divider {
	display: block;
	width: 1px;
	height: 26px;
	background: #f3f3f3;
	margin: 21px 15px;
}

.page-header .navbar-nav > li > a > img {
	width: 36px;
	margin-top: -10px;
	display: inline-block;
	vertical-align: top;
}

.page-header .navbar-nav > li.user-dropdown > a {
	margin-right: 0;
}

/* .open:not(.active)>a {
	color: #8899a6;
} */
/* 
.active.open>a i {
	color: #2b2b2b
} */

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
	color: #1f2229;
	background-color: transparent;
}

ul li a#collapsed-sidebar-toggle-button i,
.header-right ul li a#search-button i,
.header-right ul li a.notification-icon i,
.header-right ul li a.message-icon i,
.header-right ul li a.settings-icon i {
	vertical-align: inherit;
}

.header-right ul li a.settings-icon i {
	vertical-align: middle;
}

.page-header .navbar-collapse {
	padding-right: 0;
	padding-left: 0;
	overflow-x: visible;
}

.header-right .list-inline-item:not(:last-child) {
	margin-right: 1rem;
}

.header-right .dropdown-menu {
	top: 40px;
	right: -16px;
	left: auto;
	width: 305px;
	padding-bottom: 0px;
	padding: 0px;
	font-size: 13px;
	border-radius: 2px;
	border: none;
}

.header-right .dropdown-menu:before {
	content: '';
	position: absolute;
	top: -20px;
	right: 12px;
	border: 10px solid #e6e8ec;
	border-color: transparent transparent #e6e8ec transparent;
}

.header-right .select-languages {
	vertical-align: middle;
	margin-bottom: 10px;
	display: inline-block;
}

.header-right .languages-dropdown {
	top: 39px;
}

.header-right .languages-dropdown {
	width: 100%;
	padding: 10px;
}

.header-right .languages-dropdown li:hover {
	background: #f2f4f7;
}

.header-right .languages-dropdown li a {
	/* font-family: 'IBM Plex Sans', sans-serif; */
	padding: 5px 10px;
	display: block;
	font-weight: 400;
}

.header-right .languages-dropdown li img {
	margin-right: 10px;
}

.header-right .notification-icon,
.header-right .message-icon {
	vertical-align: sub;
}

.header-right .top-message-area,
.top-notifications-area {
	position: relative;
	z-index: 1;
	border: 1px solid #eee;
	border-radius: 2px;
}

.header-right .top-notifications-area .top-message-heading,
.header-right .top-message-area .top-message-heading {
	width: 100%;
	height: 50px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding-left: 1rem;
	padding-right: 1rem;
	background-color: #ffffff;
	border-bottom: 1px solid #eee;
}

.header-right .top-message-area .top-message-heading > span,
.header-right .top-notifications-area .notifications-heading > span {
	color: #ffffff;
	padding: 2px 10px 2px 10px;
	background-color: #526ef5;
	border-radius: 40px;
	font-size: 10px;
	font-weight: 500;
	/* font-family: 'IBM Plex Sans', sans-serif; */
}

.header-right .top-message-area .top-message-footer,
.header-right .top-notifications-area .notifications-footer {
	margin-top: 5px;
	border-top: 1px solid rgba(72, 94, 144, 0.16);
	padding: 10px;
	text-align: center;
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.header-right .top-header-area .nav-item.dropdown .dropdown-menu .dropdown-item {
	white-space: normal;
	color: #666b84;
	font-size: 13px;
}

.header-right .notifications-box .list-group-item div.tx-11,
.header-right .message-box .list-group-item div.tx-11 {
	font-weight: 400;
}

.header-right .notifications-box .list-group-item,
.header-right .message-box .list-group-item {
	border-bottom: none;
	border-left: none;
	border-right: none;
	border-top: 1px solid #f3f0f0;
}

.header-right .notifications-box .list-group-item:first-child,
.header-right .message-box .list-group-item:first-child {
	border-bottom: none;
	border-left: none;
	border-right: none;
	border-top: none;
}

.header-right .notifications-box .dropdown-item,
.header-right .message-box .dropdown-item {
	font-weight: 500;
	padding: 1rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	white-space: normal;
}

.avatar {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 40px;
	white-space: nowrap;
	vertical-align: bottom;
}

.avatar-md {
	width: 50px;
	height: 50px;
}

.avatar-lg {
	width: 100px;
	height: 100px;
}

.avatar-initial {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #8392a5;
	color: #fff;
	font-weight: 700;
	font-size: 15px;
	/* font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif; */
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
}

.avatar img {
	width: 100%;
	max-width: 100%;
	height: auto;
	border: 0 none;
	border-radius: 1000px;
}

.avatar i {
	width: 8px;
	height: 8px;
	display: block;
	border-radius: 50%;
	right: 0px;
	top: 25px;
	position: absolute;
	border: 2px solid #ffffff;
}

.avatar-online i {
	background-color: #11c26d;
}

.avatar-busy i {
	background-color: #eb6709;
}

.avatar-offline i {
	background-color: #526069;
}

.header-right .notifications-box .list-group-item .media-heading,
.header-right .message-box .list-group-item .media-heading {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 13px;
	font-weight: 500;
}

.header-right .notifications-box .list-group-item .media-time,
.header-right .message-box .list-group-item .media-time {
	color: #a3afb7;
	font-size: 10px;
	font-weight: 400;
}

.header-right .notifications-box .list-group-item .media-detail,
.header-right .message-box .list-group-item .media-detail {
	font-size: 12px;
	color: #8e939b;
}

.header-right .dropdown-item.active,
.dropdown-item:hover {
	color: #001f5f;
	text-decoration: none;
	background-color: #f4f4fb !important;
}

.dropdown-item:hover a {
	color: #001f5f;
	text-decoration: none;
}

.header-right .top-notifications-area .notifications-heading {
	width: 100%;
	height: 50px;
	border-bottom: 1px solid #eee;
	border-radius: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding-left: 1rem;
	padding-right: 1rem;
}

.header-right .top-message-area .top-message-heading .heading-title h6,
.top-notifications-area .notifications-heading .heading-title h6 {
	color: #2b3544;
	display: inline-block;
	margin-bottom: 0;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	/* font-family: 'IBM Plex Sans', sans-serif; */
}

.header-right .dropdown-profile {
	width: 220px;
	right: -6px;
	top: 46px;
}

.header-right .select-profile {
	margin-right: 5px;
	vertical-align: sub;
}

.header-right .user-profile-area {
	padding-bottom: 1rem;
	z-index: 1;
	position: relative;
}

.header-right .user-profile-area .user-profile-heading {
	width: 100%;
	height: 70px;
	background-color: rgba(5, 88, 183, 0.1);
	border-radius: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.header-right .user-profile-area .dropdown-item {
	white-space: normal;
	font-size: 13px;
	padding: 7px 20px;
	/* font-family: 'IBM Plex Sans', sans-serif; */
	border-left: 3px solid transparent;
	text-transform: capitalize;
}

.header-right .user-profile-area .dropdown-item:hover {
	border-left: 3px solid #001f5f;
}

.header-right .user-profile-area .profile-thumbnail {
	-webkit-box-flex: 0;
	margin-right: 10px;
}

.header-right .user-profile-area .profile-text h6 {
	font-size: 13px;
	color: #000;
	margin-bottom: 0;
}

.header-right .user-profile-area .profile-text span {
	font-size: 11px;
	color: rgba(0, 0, 0, 0.81);
	margin-bottom: 0;
	display: block;
}

.header-right .user-profile-area .dropdown-item i {
	margin-right: 10px;
	vertical-align: middle;
	font-size: 16px;
}

.header-right .notification-count,
.header-right .messages-count {
	height: 5px;
	width: 5px;
	display: block;
	background: #001f5f;
	position: relative;
	right: -10px;
	top: -22px;
	-moz-border-radius: 50px;
	border-radius: 50px;
}

.setting-sidebar {
	position: fixed;
	top: 56px;
	bottom: 0;
	background-color: #ffffff;
}

.setting-sidebar .wrapper {
	position: relative;
	height: 100%;
	width: 100%;
	padding: 20px;
	overflow-y: auto;
	border-left: 1px solid rgba(230, 230, 230, 0.7);
	-webkit-box-sizig: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.setting-sidebar .wrapper .nav-tabs .nav-link {
	border-color: #e9ecef #dee2e6 #e9ecef #dee2e6;
}

.setting-sidebar .wrapper ul li {
	color: #666b84;
}

/**********************************************************************
***********************************************************************
### Mega Menu CSS
*********************************************************************
*********************************************************************/

.collapse:not(.show) {
	display: none;
}

.navbar-collapse {
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-ms-flex-align: center;
	align-items: center;
}

.navbar-nav {
	height: 60px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	list-style: none;
}

.navbar .mega-dropdown a span.tx-10 {
	color: #bbc1c7;
}

.navbar .mega-dropdown {
	position: static !important;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
	position: relative;
}

.navbar .mega-dropdown .dropdown-menu.mega-menu {
	width: 100%;
	border-top: none;
	border-bottom: 1px solid #eee;
	border-right: none;
	border-left: none;
	border-radius: 0;
	top: 60px;
}

.navbar .dropdown-menu {
	position: absolute !important;
	margin-top: 0;
}

.navbar-nav .mega-dropdown .dropdown-menu.mega-menu div a,
.navbar-nav .mega-dropdown .dropdown-menu.mega-menu ul li a {
	color: #8392a5;
	font-weight: 300;
	padding: 5px;
	display: block;
	font-size: 13px;
}

.navbar-nav .mega-dropdown .dropdown-menu.mega-menu ul li a:hover {
	color: #001f5f;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	transition: all 0.3s;
}

/**********************************************************************
***********************************************************************
### Sidebar CSS
*********************************************************************
*********************************************************************/

.page-sidebar {
	font-weight: 400;
	width: 250px;
	background: #ffffff;
	min-height: 100%;
	float: left;
	z-index: 100;
	position: relative;
	border-right: 1px solid rgba(230, 230, 230, 0.7);
}

.page-sidebar-fixed .page-sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	z-index: 100;
	height: 100% !important;
}

body:not(.page-sidebar-fixed) .page-sidebar .slimScrollDiv {
	height: auto !important;
}

.page-sidebar-fixed .page-sidebar,
.page-header-fixed .page-sidebar,
.page-sidebar-fixed.page-header-fixed .page-sidebar {
	padding-top: 80px;
	transition: all 0.5s;
	-webkit-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
}

.page-header-fixed .page-sidebar {
	width: 250px;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition: 0.3s ease;
	transition: 0.3s ease;
}

.page-sidebar .logo {
	position: absolute;
	z-index: 30;
	top: 0;
	left: 0;
	width: 250px;
	background-color: #ffffff;
	text-align: center;
	height: 60px;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition: 0.3s ease;
	transition: 0.3s ease;
	padding: 0 15px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-right: 1px solid rgba(230, 230, 230, 0.7);
	border-bottom: 1px solid rgba(230, 230, 230, 0.7);
}

.page-sidebar-collapsed .page-sidebar .logo {
	width: 60px;
}

.logo a.logo-img img {
	max-height: 32px;
	width: auto;
}

.page-sidebar-collapsed .logo a.logo-img img.desktop-logo {
	display: none;
}

.logo a.logo-img img.small-logo {
	display: none;
	max-height: 28px;
}

.page-sidebar-collapsed .logo a.logo-img img.small-logo {
	display: block;
}

.page-header-fixed .logo {
	position: fixed;
	top: 0;
}

.page-sidebar-menu {
	margin: 10px 0;
}

.page-sidebar-fixed .page-sidebar .accordion-menu {
	margin: 10px 0 60px 0;
}

.page-sidebar-menu ul {
	padding: 0;
	margin: 0;
}

.page-sidebar-menu ul li {
	list-style-type: none;
	position: relative;
	border-left: 3px solid transparent;
}

.page-sidebar-menu ul li.menu-elements,
.page-sidebar-menu ul li.menu-extras,
.page-sidebar-menu ul li.menu-others {
	color: #a5afc2;
	text-transform: uppercase;
	font-size: 12px;
}

.page-sidebar-menu ul li.menu-elements:hover,
.page-sidebar-menu ul li.menu-extras:hover,
.page-sidebar-menu ul li.menu-others:hover {
	border-left: 3px solid transparent !important;
}

.page-sidebar-menu ul li.active > a i,
.page-sidebar-menu ul li.active > a i.accordion-icon,
.page-sidebar-menu ul li.open > a i.accordion-icon,
.page-sidebar-menu ul li a:hover i.accordion-icon {
	color: #001f5f;
}

.page-sidebar-menu ul li.active > a {
	color: #001f5f;
	position: relative;
	background: #ffffff;
}

.page-sidebar-menu ul li:hover,
.page-sidebar-menu ul > li.active,
.page-sidebar-menu ul > li.open {
	/* border-left: 3px solid #001f5f; */
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.page-sidebar-menu ul > li > ul > li:hover,
.page-sidebar-menu ul > li > ul > li,
.page-sidebar-menu ul > li > ul > li.active {
	border-left: none;
}

.page-sidebar-menu ul li a:link {
	text-decoration: none;
}

/*
.page-sidebar-menu>ul>li>a:hover i,
.page-sidebar-menu>ul>li>a:hover span,
.page-sidebar-menu>ul>li.open i,
.page-sidebar-menu>ul>li.open span{
	color: #596B8C;
}
*/

.page-sidebar-menu > ul > li > a span.badge-warning,
.page-sidebar-menu > ul > li.open span.badge-warning {
	color: #000;
}

.page-sidebar-collapsed .page-sidebar-menu > ul > li > a:hover {
	opacity: 1;
}

.page-sidebar-collapsed .page-sidebar-menu > ul > li.active > a {
	background: #f7f7fe;
}

.page-sidebar-menu > ul > li > a:hover i.accordion-icon {
	text-decoration: none;
}

.page-sidebar-menu ul li a i.menu-icon {
	margin-right: 10px;
	font-size: 17px;
	vertical-align: middle;
	line-height: 22px;
}

.page-sidebar-menu ul li a span {
	margin-top: 2px;
}

.page-sidebar-menu ul li a:hover,
.page-sidebar-menu ul li a:hover svg,
.page-sidebar-menu ul > li.active a svg,
.page-sidebar-menu ul > li.open a svg {
	color: #001f5f;
}

.page-sidebar-menu ul li a:hover,
.page-sidebar-menu ul li a:hover svg {
	background-color: #f4f4fb;
}
.page-sidebar-menu ul li.menu-divider {
	display: block;
	width: 100%;
	height: 1px;
	background-color: rgba(230, 230, 230, 0.5);
	margin: 0;
}

.page-sidebar-menu ul li a svg {
	height: 18px;
	margin-right: 7px;
}

.page-sidebar-menu ul li a i.accordion-icon {
	float: right;
	font-size: 15px;
	vertical-align: text-top;
	color: #596b8c;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

/* .page-sidebar-menu ul li.open a i.accordion-icon {
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	/*filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=-3)*/

.page-sidebar-menu ul li .sub-menu a:hover {
	color: #001f5f;
}

.accordion-menu .sub-menu {
	display: none;
	padding-left: 30px;
	padding-bottom: 10px;
	background: #ffffff;
}

.page-sidebar-collapsed .page-content {
	width: calc(100% - 60px);
}

.page-sidebar-fixed .page-content {
	margin-left: 250px;
}

.page-sidebar-fixed.page-sidebar-collapsed .page-content {
	margin-left: 60px;
}

.page-sidebar-collapsed .accordion-menu .sub-menu {
	padding-bottom: 0;
	padding-left: 0;
}

.accordion-menu .sub-menu li.animation a {
	opacity: 1;
}

.accordion-menu .sub-menu li a {
	border-top: 0;
	padding: 8px 20px;
	font-size: 13px;
}

.accordion-menu .sub-menu li a:hover:before,
.page-sidebar-menu ul li ul li.active > a:before {
	content: '';
	border-radius: 50%;
	position: absolute;
	top: 15px;
	left: -5px;
	width: 5px;
	height: 5px;
	background: #001f5f;
}

.page-sidebar-collapsed .page-sidebar-menu ul li ul.sub-menu li a:hover:before,
.page-sidebar-collapsed .page-sidebar-menu ul li ul.sub-menu li.active > a:before {
	content: none;
}

.accordion-menu .sub-menu li a.active {
	color: #000;
}

.accordion-menu .sub-menu li a.active:hover {
	color: #000 !important;
}

.page-sidebar-collapsed .page-sidebar {
	width: 60px;
}

.page-sidebar-collapsed.page-sidebar-fixed .page-sidebar {
	height: 100%;
}

.page-sidebar-collapsed:not(.page-sidebar-fixed) .page-sidebar .slimScrollDiv {
	overflow: visible !important;
	height: calc(100% - 83px) !important;
}

.page-sidebar-collapsed .page-sidebar-menu > ul > li {
	width: 60px;
	position: relative;
}

.page-sidebar-collapsed .page-sidebar-menu > ul > li > a {
	width: 57px;
	position: relative;
	border: 1px solid transparent;
}

.page-sidebar-collapsed .page-sidebar-menu ul li .sub-menu,
.page-sidebar-collapsed .page-sidebar-menu ul li.active .sub-menu,
.page-sidebar-collapsed .page-sidebar-menu ul li.open .sub-menu {
	display: none !important;
	width: 190px;
}

.page-sidebar-collapsed .page-sidebar-menu ul li a span:not(.label) {
	margin-left: 30px;
}

.page-sidebar-collapsed .page-sidebar-menu ul li a span,
.page-sidebar-collapsed .page-sidebar-menu ul li a i.accordion-icon {
	display: none;
}

.page-sidebar-collapsed .page-sidebar-menu ul li .sub-menu {
	position: absolute;
	left: 56px;
	z-index: 9999;
	background: #ffffff;
	border-left: 1px solid rgba(230, 230, 230, 0.7);
	border-right: 1px solid rgba(230, 230, 230, 0.7);
	border-bottom: 1px solid rgba(230, 230, 230, 0.7);
}

.page-sidebar-collapsed .page-sidebar-menu ul li .sub-menu a {
	opacity: 1;
	padding: 8px 25px;
}

.page-sidebar-collapsed .page-sidebar-menu ul li a:hover + .sub-menu,
.page-sidebar-collapsed .page-sidebar-menu ul li .sub-menu:hover,
.page-sidebar-collapsed .page-sidebar-menu ul li:hover a span,
.page-sidebar-collapsed .page-sidebar-menu ul li a:hover span {
	display: inline !important;
}

.page-sidebar-collapsed .page-sidebar-menu > ul > li:hover > a {
	width: 246px;
	color: #596b8c;
	background: #f7f7fe;
	border: 1px solid rgba(230, 230, 230, 0.7);
}

.page-sidebar .slimScrollBar {
	display: none !important;
}

.page-sidebar-collapsed .page-sidebar.fixed-sidebar-scroll .slimScrollDiv {
	width: 250px !important;
}

.page-sidebar-collapsed .page-sidebar-menu ul li.menu-elements,
.page-sidebar-collapsed .page-sidebar-menu ul li.menu-extras,
.page-sidebar-collapsed .page-sidebar-menu ul li.menu-others,
.page-sidebar-collapsed .sidebar-footer {
	display: none;
}

.sidebar-footer {
	background: #ffffff;
	border: 1px solid rgba(230, 230, 230, 0.7);
	clear: both;
	bottom: 0;
	display: block;
	margin: 0 !important;
	padding: 0 !important;
	position: fixed;
	width: 250px !important;
	z-index: 1000;
	transition: 0.5s;
	-webkit-transition: 0.5s;
	-ms-transition: 0.5s;
	-moz-transition: 0.5s;
	-o-transition: 0.5s;
}

.sidebar-footer .pull-left {
	padding: 12px 0 8px;
	text-align: center;
	width: 25%;
}

.sidebar-footer a.pull-left {
	color: #596b8c;
}

.sidebar-footer a.pull-left:hover {
	text-decoration: none;
	background: #f7f7fe;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

#sidebar-toggle-button-close {
	display: none;
}

/* .custom-fieldset {
	background: #ffffff;
	border: 1px solid rgba(230, 230, 230, 0.7);
	padding: 1.5rem;
	border-radius: .25rem;
	font-size: 11px;
}

.custom-fieldset label {
	width: auto;
	padding: .625rem 1rem;
	background: #ffffff;
	margin-top: -42px;
	margin-left: 50px;
	font-size: 13px;
	display: block;
	float: left;
	text-transform: uppercase;
} */

/**********************************************************************
***********************************************************************
### Mailbox CSS
*********************************************************************
*********************************************************************/

.email-left-sidebar ul li a:hover,
.email-left-sidebar ul li a.active {
	color: #001f5f;
	background: #f5f6fa;
}

.email-right-sidebar tr.active {
	background: #f5f6fa;
	font-weight: bold;
}

.email-right-sidebar .table td,
.email-right-sidebar .table th,
.email-right-sidebar .table thead th {
	white-space: normal;
}

#onlineBox {
	border: 1px solid rgba(230, 230, 230, 0.7);
}

/**********************************************************************
***********************************************************************
### Widgets CSS 
*********************************************************************
*********************************************************************/

.baseline .baseline-list .baseline-info {
	margin-top: 10px;
	margin-bottom: 10px;
	margin-left: 20px;
}

.baseline-info {
	border-color: #36a2f5 !important;
}

.baseline-border {
	border-left: 1px solid #e5ebf8;
}

.baseline,
.baseline .baseline-list {
	position: relative;
	border-color: #e5ebf8;
}

.baseline .baseline-list {
	padding-bottom: 1px;
}

.baseline,
.baseline .baseline-list {
	position: relative;
	border-color: #e5ebf8;
}

.baseline .baseline-list:before {
	display: table;
	content: ' ';
}

.baseline .baseline-list:after {
	display: table;
	position: absolute;
	top: 18px;
	left: 0;
	width: 12px;
	height: 12px;
	margin-left: -6px;
	content: '';
	border-width: 1px;
	border-style: solid;
	border-color: inherit;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 0 0 0 3px #e5ebf8 inset;
}

.baseline-primary {
	border-color: #001f5f !important;
}

.baseline-primary:after {
	box-shadow: 0 0 0 3px #001f5f inset !important;
}

.baseline-success {
	border-color: #34bfa3 !important;
}

.baseline-success:after {
	box-shadow: 0 0 0 3px #34bfa3 inset !important;
}

.baseline-warning {
	border-color: #eac459 !important;
}

.baseline-warning:after {
	box-shadow: 0 0 0 3px #eac459 inset !important;
}

.baseline-info {
	border-color: #36a2f5 !important;
}

.baseline-info:after {
	box-shadow: 0 0 0 3px #36a2f5 inset !important;
}

.activity {
	position: relative;
	border-left: 2px dotted #eff2f9;
	margin: 20px 20px 0 20px;
}

.activity span {
	color: #bbc1c7;
}

.activity i,
.activity img {
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	border-radius: 100%;
	position: absolute;
	left: -22px;
	color: #4d79f6;
	background-color: #f3f6f7;
	font-size: 20px;
	margin-top: -10px;
	-webkit-box-shadow: 0 0 0 0.5px #f3f6f7;
	box-shadow: 0 0 0 0.5px #f3f6f7;
}

.activity .item-info {
	margin-left: 40px;
	margin-bottom: 30px;
}

.card-activities .media {
	position: relative;
}

.card-activities .media::before {
	content: '';
	position: absolute;
	top: 0;
	left: 25px;
	height: 100%;
	border-left: 2px dotted #eff2f9;
	z-index: 40;
}

.card-activities .activity-icon {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	color: #fff;
	border-radius: 100%;
	position: relative;
	z-index: 50;
}

.card-activities h6 {
	margin-bottom: 3px;
}

.card-activities span {
	color: #bbc1c7 !important;
}

/**********************************************************************
***********************************************************************
### UI Elements CSS
*********************************************************************
*********************************************************************/

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	border: 1px solid rgba(230, 230, 230, 0.7);
	background-clip: border-box;
	border-radius: 3px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
	box-shadow: 0 3px 15px rgba(0, 0, 0, 0.05);
	border-radius: 10px;
}

.shadow-none,
.card:hover {
	box-shadow: none;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}

.card-accent-primary {
	border-top-color: #20a8d8 !important;
	border-top-width: 2px !important;
}

.card-accent-secondary {
	border-top-color: #c8ced3 !important;
	border-top-width: 2px !important;
}

.card-accent-success {
	border-top-color: #4dbd74 !important;
	border-top-width: 2px !important;
}

.card-accent-danger {
	border-top-color: #f86c6b !important;
	border-top-width: 2px !important;
}

.card-accent-warning {
	border-top-color: #ffc107 !important;
	border-top-width: 2px !important;
}

.card-accent-orange {
	border-top-color: #e25a0e !important;
	border-top-width: 2px !important;
}

.card-accent-info {
	border-top-color: #63c2de !important;
	border-top-width: 2px !important;
}

.card-accent-dark {
	border-top-color: #2f353a !important;
	border-top-width: 2px !important;
}

.card-footer {
	border-top: 1px solid #ebf2f7;
}

.brand-card {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	min-width: 0;
	margin-bottom: 1.5rem;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid #eaedef;
	border-radius: 0.25rem;
}

.brand-card-header {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 6rem;
	border-radius: 0.25rem 0.25rem 0 0;
}

.brand-card-body {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: row;
	flex-direction: row;
	padding: 0.75rem 0;
	text-align: center;
}

.brand-card-body > :not(:last-child) {
	border-right: 1px solid #eaedef;
}

.brand-card-body > * {
	-ms-flex: 1;
	flex: 1;
	padding: 0.1875rem 0;
}

.text-value {
	font-size: 1.3125rem;
	font-weight: 300;
}

.card .card-header {
	color: #343a40;
	background-color: rgb(255, 255, 255);
	align-items: center;
	padding: 0.75rem 0.9375rem;
	text-decoration: none !important;
	border-bottom: 1px solid rgba(230, 230, 230, 0.7);
	outline: 0 !important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	-ms-flex-align: center;
	border-radius: 10px;
}

.card .card-header.card-header-inverse {
	background: #1a1a1a;
	color: #fff;
}

.card .card-header.card-header-inverse .card-header-title {
	font-weight: 600;
}

.card .card-header.card-header-inverse.card-header-with-tabs {
	border-bottom: 1px solid #343a40;
}

.card .card-header.card-header-inverse .card-header-tabs {
	border: 0;
}

.card .card-header.card-header-inverse .card-header-tabs .nav-link.active {
	background: #fff;
	border-color: transparent;
}

.card .card-header.card-header-inverse .card-header-tabs .nav-link:focus,
.card .card-header.card-header-inverse .card-header-tabs .nav-link:hover {
	border-color: transparent;
}

.card .card-header.card-header-with-tabs {
	border-bottom: 1px solid #ccc;
}

.card .card-header .card-header-tabs {
	border: 0;
}

.card .card-header .card-header-tabs .nav-link.active {
	background: #fff;
}

.card .card-header .card-header-title {
	color: #001737;
	font-size: 13px;
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	margin: 0;
	text-transform: capitalize;
	/* font-family: 'IBM Plex Sans', sans-serif; */
}

.card .card-header .card-header-btn {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.card .card-header .card-header-btn .btn {
	padding: 0;
	width: 1.125rem;
	height: 1.125rem;
	line-height: 1rem;
	-webkit-border-radius: 0.1rem;
	-moz-border-radius: 0.1rem;
	-o-border-radius: 0.1rem;
	-ms-border-radius: 0.1rem;
	border-radius: 0.1rem;
}

.card .card-header .card-header-btn .btn + .btn {
	margin-left: 0.3125rem;
}

.card .card-body {
	-webkit-box-flex: 1 1 auto;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}

.card .card-footer,
.card .card-loader {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}

.card .card-footer {
	padding: 0.75rem 0.9375rem;
	background: #f2f2f2;
	border: 0;
}

.card .card-loader {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1020;
	background: rgba(255, 255, 255, 0.9);
	border-radius: 0.375rem;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.card .card-loader.card-loader-inverse {
	background: rgba(0, 0, 0, 0.9);
}

.card.card-inverse {
	background: #333;
}

.card.card-expand {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1060;
	border-radius: 0;
	margin: 0 !important;
}

.card.card-expand.card-loading {
	position: fixed;
}

.card.card-expand .card-body,
.card.card-expand .card-header {
	border-radius: 0;
}

.card.card-expand .card-body {
	-webkit-box-flex: 1 0 0;
	-ms-flex: 1 0 0;
	flex: 1 0 0;
}

.card.card-loading .card-header {
	position: relative;
	z-index: 1030;
}

.card.card-loading,
.card.card-loading .card-body {
	position: relative;
}

.webnots-notification-box {
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	color: #ffffff;
	/* font-family: verdana, 'open sans', sans-serif; */
	margin-bottom: 25px;
	padding: 10px 14px 10px 44px;
	position: relative;
	box-shadow: 0px 1px 5px #999;
	/* width: -moz-fit-content;
	width: -webkit-fit-content;
	width: fit-content; */
}

.webnots-notification-box:before {
	font-family: FontAwesome;
	font-size: 20px;
	left: 15px;
	bottom: 5px;
	position: absolute;
}

.webnots-success {
	background-color: #2ecc71;
}

.webnots-success:before {
	content: '\f00c';
	margin-left: -2px;
}

.webnots-failure {
	background-color: #e74c3c;
}

.webnots-failure:before {
	content: '\f00d';
}

.webnots-warning {
	background-color: #e67e22;
}

.webnots-warning:before {
	content: '\f12a';
	margin-left: 5px;
}

.webnots-purple {
	background-color: #663bf3;
}

.webnots-purple:before {
	content: '\f0ec';
}

.webnots-information {
	background-color: #3498db;
}

.webnots-information:before {
	content: '\f129';
	margin-left: 4px;
}

.webnots-question {
	background-color: #f1c40f;
}

.webnots-question:before {
	content: '\f128';
	margin-left: 2px;
}

.webnots-tip {
	background-color: #16a085;
}

.webnots-tip:before {
	content: '\f0eb';
	margin-left: 2px;
}

.webnots-notice {
	background-color: #bea474;
}

.webnots-notice:before {
	content: '\f0a1';
	margin-left: -1px;
}

.info_alert {
	border: 1px solid #cccccc;
	background-color: #e0e0e0;
	padding: 15px;
	border-radius: 3px;
	box-shadow: 0px 1px 5px #999;
	margin: 10px 0px;
}

.warning_alert {
	border: 1px solid #fe7736 !important;
	background-color: #ffbd59;
	padding: 15px;
	border-radius: 3px;
	box-shadow: 0px 1px 5px #999;
	margin: 10px 0px;
	color: white;
}

.danger_alert {
	border: 1px solid #ff0f0f !important;
	background-color: #ff8080 !important;
	padding: 15px;
	border-radius: 3px;
	box-shadow: 0px 1px 5px #999;
	margin: 10px 0px;
	color: white !important;
}

.success_alert {
	border: 1px solid #83cd52 !important;
	background-color: #8de182 !important;
	padding: 15px;
	border-radius: 3px;
	box-shadow: 0px 1px 5px #999;
	margin: 10px 0px;
	color: #060 !important;
}

.error-notice {
	margin: 5px 5px;
	/* Making sure to keep some distance from all side */
}

.oaerror {
	margin: 0 auto;
	background-color: #ffffff;
	padding: 20px;
	border: 1px solid #eee;
	border-left-width: 5px;
	border-radius: 3px;
	margin: 17px auto;
}

.danger {
	border-left-color: #d9534f;
	/* Left side border color */
	background-color: rgba(217, 83, 79, 0.1);
	/* Same color as the left border with reduced alpha to 0.1 */
}

.danger strong {
	color: #d9534f;
}

.warning {
	border-left-color: #f0ad4e;
	background-color: rgba(240, 173, 78, 0.1);
}

.warning strong {
	color: #f0ad4e;
}

.info {
	border-left-color: #5bc0de;
	background-color: rgba(91, 192, 222, 0.1);
}

.info strong {
	color: #5bc0de;
}

.success {
	border-left-color: #2b542c;
	background-color: rgba(43, 84, 44, 0.1);
}

.success strong {
	color: #2b542c;
}

.btn {
	font-size: 13px;
	border-radius: 0.15rem;
}

.btn-oblong {
	border-radius: 50px;
}

.btn-blue {
	color: #fff !important;
	background-color: #001f5f !important;
	/* border-color: #001f5f !important; */
}

.btn-blue:hover {
	color: #fff !important;
	background-color: #001f5f !important;
	border-color: #001f5f;
}

.btn-outline-blue {
	color: #001f5f;
	background-color: transparent;
	background-image: none;
	/* border-color: #001f5f; */
}

.btn-outline-blue:hover {
	color: #ffffff;
	background-color: #001f5f !important;
	background-image: none;
	border-color: #001f5f !important;
}

.btn-outline-blue:not(:disabled):not(.disabled).active,
.btn-outline-blue:not(:disabled):not(.disabled):active,
.show > .btn-outline-blue.dropdown-toggle {
	color: #001f5f;
	background-color: #001f5f;
	border-color: #001f5f;
}

.btn-outline-secondary {
	border-color: #ebf2f7;
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
	box-shadow: none !important;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
	color: #403a3a;
	background-color: #eaf1f6;
	border-color: #ebf2f7;
}

.btn-indigo {
	color: #fff;
	background-color: #6610f2;
	border-color: #5b0cdd;
}

.btn-indigo:active,
.btn-indigo.active {
	background-color: #560bd0;
	background-image: none;
	border-color: #4709ac;
}

.btn-purple {
	color: #fff;
	background-color: #6f42c1;
	border-color: #643ab0;
}

.btn-purple:active,
.btn-purple.active {
	background-color: #5e37a6;
	background-image: none;
	border-color: #4e2d89;
}

.btn-pink {
	color: #fff;
	background-color: #e41c78;
	border-color: #c21766;
}

.btn-pink:active,
.btn-pink.active {
	background-color: #e41c78;
	background-image: none;
	border-color: #c21766;
}

.btn-teal {
	color: #fff;
	background-color: #008c77 !important;
	border-color: #006656 !important;
}
.btn-teal:hover {
	color: #fff !important;
	background-color: #0ad4b6 !important;
	border-color: #006656 !important;
}

.btn-teal:active,
.btn-teal.active {
	background-color: #008c77;
	background-image: none;
	border-color: #006656;
}

.btn-light {
	color: #111 !important;
	background-color: #f3f6f9 !important;
	border-color: #e1e6ea;
}

.btn-light:active,
.btn-light.active {
	background-color: #e2e6ea;
	background-image: none;
	border-color: #dae0e5;
}

.btn-outline-pink {
	color: #e41c78;
	background-color: transparent;
	background-image: none;
	border-color: #e41c78;
}

.btn-outline-pink:hover {
	color: #fff;
	background-color: #e41c78;
	border-color: #e41c78;
}

.btn.btn-label-primary {
	background-color: rgba(93, 120, 255, 0.1);
	color: #001f5f !important;
}

a.btn.btn-label-primary:hover {
	background-color: #001f5f;
	color: #fff !important;
}

.btn.btn-label-warning {
	background-color: rgba(255, 184, 34, 0.2);
	color: #ffb822;
}

a.btn.btn-label-warning:hover {
	background-color: #f3aa12;
	color: #111 !important;
}

.btn.btn-label-danger {
	background-color: rgba(253, 57, 122, 0.1);
	color: #fd397a !important;
}

a.btn.btn-label-danger:hover {
	background-color: #ff0000;
	color: #fff !important;
}

.btn.btn-label-success {
	background-color: rgba(10, 187, 135, 0.2);
	color: #0abb87 !important;
}

a.btn.btn-label-success:hover {
	background-color: #2bad0d;
	color: #fff !important;
}

.btn-with-icon > div {
	position: relative;
	display: flex;
}

.btn-with-icon {
	padding: 0;
	display: inline-block;
	border-width: 0;
}

.btn-with-icon > div > span.icon {
	font-size: 13px;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 3px;
}

.btn-with-icon > div > span {
	font-size: 13px;
	display: flex;
	height: 100%;
	align-items: center;
}

.btn-brand {
	margin-bottom: 4px;
}

.btn-brand {
	border: 0;
}

.btn-brand i {
	display: inline-block;
	width: 2.0625rem;
	margin: -0.375rem -0.75rem;
	line-height: 2.0625rem;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.2);
}

.btn-brand i + span {
	font-size: 13px;
	margin-left: 1.5rem;
}

.btn-behance {
	color: #fff;
	background-color: #1769ff;
	border-color: #1769ff;
}

.btn-css3 {
	color: #fff;
	background-color: #0170ba;
	border-color: #0170ba;
}

.btn-dribbble {
	color: #fff;
	background-color: #ea4c89;
	border-color: #ea4c89;
}

.btn-dropbox {
	color: #fff;
	background-color: #007ee5;
	border-color: #007ee5;
}

.btn-facebook {
	color: #fff;
	background-color: #3b5998;
	border-color: #3b5998;
}

.btn-flickr {
	color: #fff;
	background-color: #ff0084;
	border-color: #ff0084;
}

.btn-github {
	color: #fff;
	background-color: #4183c4;
	border-color: #4183c4;
}

.btn-google-plus {
	color: #fff;
	background-color: #d34836;
	border-color: #d34836;
}

.btn-html5 {
	color: #fff;
	background-color: #e34f26;
	border-color: #e34f26;
}

.btn-google {
	color: #fff;
	background-color: #517fa4;
	border-color: #517fa4;
}

.btn-linkedin {
	color: #fff;
	background-color: #4875b4;
	border-color: #4875b4;
}

.btn-openid {
	color: #23282c;
	background-color: #f78c40;
	border-color: #f78c40;
}

.btn-pinterest {
	color: #fff;
	background-color: #cb2027;
	border-color: #cb2027;
}

.btn-reddit {
	color: #fff;
	background-color: #ff4500;
	border-color: #ff4500;
}

.btn-spotify {
	color: #fff;
	background-color: #7ab800;
	border-color: #7ab800;
}

.btn-stack-overflow {
	color: #fff;
	background-color: #fe7a15;
	border-color: #fe7a15;
}

.btn-tumblr {
	color: #fff;
	background-color: #32506d;
	border-color: #32506d;
}

.btn-twitter {
	color: #fff;
	background-color: #00aced;
	border-color: #00aced;
}

.btn-vimeo {
	color: #23282c;
	background-color: #aad450;
	border-color: #aad450;
}

.btn-vk {
	color: #fff;
	background-color: #45668e;
	border-color: #45668e;
}

.btn-xing {
	color: #fff;
	background-color: #026466;
	border-color: #026466;
}

.btn-yahoo {
	color: #fff;
	background-color: #400191;
	border-color: #400191;
}

.btn-youtube {
	color: #fff;
	background-color: #b00;
	border-color: #b00;
}

.btn-group-sm > .btn,
.btn-sm {
	font-size: 0.75rem;
}

.badge {
	display: inline-block;
	font-size: 10px;
	font-weight: 400;
	padding: 3px 6px;
	border: 1px solid transparent;
	min-width: 10px;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	/* border-radius: 99999px; */
}

.badge.badge-default {
	background-color: #b0bec5;
}

.badge.badge-outlined {
	background-color: transparent;
}

.badge.badge-outlined.badge-default {
	border-color: #b0bec5;
	color: #b0bec5;
}

.badge.badge-outlined.badge-primary {
	border-color: #2196f3;
	color: #2196f3;
}

.badge.badge-outlined.badge-secondary {
	border-color: #323a45;
	color: #323a45;
}

.badge.badge-outlined.badge-success {
	border-color: #64dd17;
	color: #64dd17;
}

.badge.badge-outlined.badge-warning {
	border-color: #ffa707;
	color: #fff;
}

.badge.badge-outlined.badge-info {
	border-color: #29b6f6;
	color: #29b6f6;
}

.badge.badge-outlined.badge-danger {
	border-color: #ef1c1c;
	color: #ef1c1c;
}

.badge-pill {
	padding-right: 0.6em !important;
	padding-left: 0.6em !important;
	border-radius: 10rem !important;
}

ul.accordion-menu li a span.badge.badge-primary,
ul.accordion-menu li a span.badge.badge-secondary,
ul.accordion-menu li a span.badge.badge-success,
ul.accordion-menu li a span.badge.badge-danger,
ul.accordion-menu li a span.badge.badge-info,
ul.accordion-menu li a span.badge.badge-warning {
	padding: 2px 5px 3px;
	border-radius: 3px;
}

ul.accordion-menu li a span.badge.badge-primary[href]:focus,
ul.accordion-menu li a span.badge.badge-primary[href]:hover,
ul.accordion-menu li a span.badge.badge-secondary[href]:focus,
ul.accordion-menu li a span.badge.badge-secondary[href]:hover,
ul.accordion-menu li a span.badge.badge-info[href]:focus,
ul.accordion-menu li a span.badge.badge-info[href]:hover,
ul.accordion-menu li a span.badge.badge-success[href]:focus,
ul.accordion-menu li a span.badge.badge-success[href]:hover,
ul.accordion-menu li a span.badge.badge-danger[href]:focus,
ul.accordion-menu li a span.badge.badge-danger[href]:hover,
.badge.badge-outlined.badge-danger[href]:focus,
.badge.badge-outlined.badge-danger[href]:hover,
.badge.badge-outlined.badge-success[href]:focus,
.badge.badge-outlined.badge-success[href]:hover {
	color: #fff !important;
}

ul.accordion-menu li a span.badge.badge-warning[href]:focus,
ul.accordion-menu li a span.badge.badge-warning[href]:hover {
	color: #000 !important;
}

.text-primary,
a.text-primary:hover {
	color: #001f5f !important;
}

.text-secondary,
a.text-secondary:hover {
	color: #6c757d;
}

.text-info,
a.text-info:hover {
	color: #0391d1;
}

.text-warning,
a.text-warning:hover {
	color: #db9a00;
}

.text-danger,
a.text-danger:hover {
	color: #f22314;
}

.text-success,
a.text-success:hover {
	color: #79af3a;
}

.text-mint,
a.text-mint:hover {
	color: #1f897f;
}

.text-purple,
a.text-purple:hover {
	color: #953ca4;
}

.text-pink,
a.text-pink:hover {
	color: #ed417b;
}

.text-dark,
a.text-dark:hover {
	color: #001737 !important;
}

.toast-info {
	background: #001f5f !important;
	/* font-family: 'IBM Plex Sans', sans-serif; */
	font-weight: 400;
}

.modal-title {
	float: left;
}

.modal-header h4.modal-title {
	/* font-family: "Open Sans", sans-serif; */
	font-weight: 300;
	float: left;
	font-size: 20px;
	margin-top: -5px;
}

.modal-body label {
	/* font-family: "Open Sans", sans-serif; */
	font-weight: 400;
}

.modal-header {
	position: relative;
	padding: 1.25rem 2.5625rem 1.25rem 1.5625rem;
	display: -ms-initial;
	display: initial;
}

.sweet-alert .sa-icon.sa-error {
	border-color: #d9534f;
}

.sweet-alert .sa-icon.sa-error {
	border-color: #d9534f;
}

.sweet-alert .sa-icon.sa-error .sa-line {
	background-color: #d9534f;
}

.sweet-alert .sa-icon.sa-success .sa-placeholder {
	border: 4px solid rgba(2, 188, 119, 0.2);
}

.sweet-alert .sa-icon.sa-success .sa-line {
	background-color: #02bc77;
}

.sweet-alert .sa-icon.sa-success {
	border-color: #02bc77;
}

.sweet-alert .sa-icon.sa-info {
	border-color: #28c3d7;
}

.sweet-alert .sa-icon.sa-info::before,
.sweet-alert .sa-icon.sa-info::after {
	background-color: #28c3d7;
}

.progress-bar {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	transition: width 0.6s ease;
}

.progress-xs {
	height: 4px;
}

.progress-group-bars .progress:not(:last-child) {
	margin-bottom: 2px;
}

.progress-group-prepend {
	-ms-flex: 0 0 100px;
	flex: 0 0 100px;
	-ms-flex-item-align: center;
	align-self: center;
}

@keyframes spinner-border {
	to {
		transform: rotate(360deg);
	}
}

.spinner-border {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: text-bottom;
	border: 0.25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	animation: spinner-border 0.75s linear infinite;
}

.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: #6d7996;
	background-color: #fff;
	border: 1px solid #dee2e6;
}

.page-item.disabled .page-link {
	color: #6c757d !important;
}

.page-item.active .page-link {
	color: #fff !important;
	background-color: #001f5f;
	border-color: #556ee9;
}

.page-link:hover {
	color: #001f5f;
}

/**********************************************************************
***********************************************************************
### Icons CSS
*********************************************************************
*********************************************************************/

.icons-list {
	border-left: 1px solid #eff1f5;
	border-top: 1px solid #eff1f5;
}

.icons-list > div {
	color: #adb5c3;
	background: #ffffff;
	border-bottom: 1px solid #eff1f5;
	border-right: 1px solid #eff1f5;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	padding: 15px 15px;
}

.icons-list > div i {
	display: inline-block;
	font-size: 20px;
	text-align: left;
	padding: 0 15px;
}

.icons-list > div:hover,
.icons-list > div:hover span {
	color: #fff !important;
	background: #001f5f;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	-ms-transition: all 0.3s;
	transition: all 0.3s;
}

.icons-list .flag-icon {
	margin: 0 10px;
}

/**********************************************************************
***********************************************************************
### Components CSS 
*********************************************************************
*********************************************************************/

.datepicker {
	font-family: inherit;
	font-size: 13px;
}

.datepicker--cell.-current- {
	color: #001f5f;
}

.datepicker--cell.-selected-,
.datepicker--cell.-selected-.-focus-,
.datepicker--cell.-selected-.-current- {
	background: #001f5f;
}

.datepicker--day-name {
	color: #001f5f;
	font-weight: 600;
}

.bootstrap-timepicker-widget table td a {
	border: transparent;
	width: 70%;
	border-radius: 0;
	padding: 0;
}

.bootstrap-timepicker-widget table td a:hover {
	background-color: transparent;
	border-radius: 0;
	border: 1px solid #ddd;
}

.bootstrap-timepicker-widget table td input {
	width: 30px;
	margin: 0;
	text-align: center;
	border-radius: 0;
}

.bootstrap-timepicker-widget .glyphicon {
	font-family: 'Ionicons';
}

.bootstrap-timepicker-widget .glyphicon-chevron-up::before {
	content: '\f3d8';
}

.bootstrap-timepicker-widget .glyphicon-chevron-down::before {
	content: '\f3d0';
}

.minicolors-theme-default .minicolors-input {
	height: auto;
}

.minicolors-theme-default .minicolors-swatch {
	top: 6px;
	left: 6px;
	width: 20px;
	height: 20px;
}

.minicolors-theme-default .minicolors-input {
	padding-left: 30px;
}

.minicolors input[type='hidden'] + .minicolors-swatch {
	width: 22px;
}

.bootstrap-select .dropdown-menu li a {
	font-size: 13px;
}

.bootstrap-select .btn-light {
	color: #666 !important;
	background-color: transparent !important;
}

.bootstrap-select .btn-light:focus,
.bootstrap-select .btn-light:active {
	box-shadow: none !important;
	transition-duration: 0.5s;
	outline: none !important;
}

.bootstrap-select .bs-ok-default:after {
	border-width: 0 0.025em 0.025em 0 !important;
}

.note-toolbar.card-header {
	display: block;
}

.note-editor.note-frame {
	border: 1px solid #ebf2f7;
}

.note-editor.note-frame .note-statusbar {
	border-top: 1px solid #ebf2f7;
}

.note-toolbar .note-btn,
.note-children-container .note-btn {
	color: #111 !important;
	background-color: #ffffff !important;
	border-color: #e4e6e8;
}

.note-popover .popover-content,
.card-header.note-toolbar {
	background: #f5f7f9;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
	border: 1px solid #ebf2f7;
}

/**********************************************************************
***********************************************************************
### Forms CSS 
*********************************************************************
*********************************************************************/

.is-warning,
.is-warning,
.is-warning {
	border-color: #f49917 !important;
}

.form-control-lg {
	line-height: 32px;
	font-size: 14px;
	padding: 7px 16px;
}

.custom-file-label {
	height: calc(2rem + 2px);
	border: none;
}

.custom-file-input:focus ~ .custom-file-label {
	border-color: inherit;
	box-shadow: none;
}

.custom-file-label::after {
	background-color: #ebf2f7;
	border-left: none;
	line-height: 25px;
}

.form-layout-2 .form-group,
.form-layout-3 .form-group {
	position: relative;
	border: 1px solid #eeeeee;
	padding: 20px 20px;
	margin-bottom: 0;
	height: 100%;
	transition: all 0.2s ease-in-out;
}

.form-layout-4,
.form-layout-5 {
	padding: 30px;
	border: 1px solid #eeeeee;
}

.form-layout-4 .form-control-label,
.form-layout-5 .form-control-label {
	display: flex;
	align-items: center;
	margin-bottom: 0;
}

.wizard > .content {
	background: #ebf2f7;
	min-height: 20em;
}

.wizard > .steps .current a .number {
	border-color: #fff;
}

.wizard > .steps a .number {
	font-size: 14px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 25px;
	height: 25px;
	border: 1px solid #adb5bd;
	border-radius: 50px;
}

.wizard > .steps .current a {
	background: #001f5f;
	color: #fff;
	cursor: default;
}

.wizard > .actions a {
	background: #001f5f;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

.wizard > .steps a .number {
	border: 1px solid #ffffff;
}

.wizard > .steps .disabled a {
	background: #ebf2f7;
}

#wizard5 .steps ul {
	display: flex;
}

#wizard5 .steps ul li {
	width: 100%;
}

.wizard > .steps > ul li .title {
	display: none;
	margin-left: 10px;
}

.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
	background: #001f5f;
}

.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active {
	background: #001f5f;
}

.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
	background: #001f5f;
	text-decoration: none;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}

.was-validated .form-control:valid,
.was-validated .dataTables_filter input:valid,
.dataTables_filter .was-validated input:valid,
.form-control.is-valid,
.dataTables_filter input.is-valid,
.dataTables_filter input.parsley-success,
input.form-control.parsley-success,
textarea.form-control.parsley-success,
.was-validated .custom-select:valid,
.custom-select.is-valid,
input.custom-select.parsley-success,
textarea.custom-select.parsley-success {
	border-color: #23bf08 !important;
}

.was-validated .form-control:invalid,
.was-validated .dataTables_filter input:required,
.dataTables_filter .was-validated input:invalid,
.form-control.is-invalid,
.dataTables_filter input.is-invalid,
.dataTables_filter input.parsley-error,
input.form-control.parsley-error,
textarea.form-control.parsley-error,
.was-validated .custom-select:invalid,
.custom-select.is-invalid,
input.custom-select.parsley-error,
textarea.custom-select.parsley-error {
	border-color: #dc3545 !important;
}

.parsley-errors-list {
	color: #dc3545;
	list-style: none;
	font-size: 12px;
	padding: 0;
	margin-bottom: 0;
}

.parsley-errors-list li {
	margin: 5px 0 0;
}

.parsley-checkbox.parsley-error {
	display: inline-block;
	padding: 10px;
	border: 1px solid #dc3545;
}

.parsley-checkbox.parsley-success {
	display: inline-block;
	padding: 10px;
	border: 1px solid #23bf08;
}

.parsley-error select.select2 {
	border: 1px solid #dc3545 !important;
}

.parsley-success select.select2 {
	border: 1px solid #23bf08 !important;
}

.parsley-errors-list {
	color: #dc3545;
	list-style: none;
	font-size: 12px;
	padding: 0;
	margin-bottom: 0;
}

.parsley-style-1 .parsley-input.parsley-error .form-control,
.parsley-style-1 .parsley-input.parsley-error .dataTables_filter input,
.dataTables_filter .parsley-style-1 .parsley-input.parsley-error input {
	border-color: #dc3545 !important;
}

.parsley-style-1 .parsley-input.parsley-success .form-control,
.parsley-style-1 .parsley-input.parsley-success .dataTables_filter input,
.dataTables_filter .parsley-style-1 .parsley-input.parsley-success input {
	border-color: #23bf08;
}

.parsley-errors-list {
	color: #dc3545;
	list-style: none;
	font-size: 12px;
	padding: 0;
	margin-bottom: 0;
}

.parsley-style-1 .parsley-errors-list.filled {
	background: #ff4444;
	color: #fff;
	padding: 7px 10px;
	-webkit-border-bottom-right-radius: 2px;
	-moz-border-bottom-right-radius: 2px;
	border-bottom-right-radius: 2px;
	-webkit-border-bottom-left-radius: 2px;
	-moz-border-bottom-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.file-group-inline {
	display: inline-block;
}

.file-group {
	position: relative;
	overflow: hidden;
}

.file-group input[type='file'] {
	position: absolute;
	top: 0px;
	left: 0px;
	opacity: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
}

.form-control.file-value {
	cursor: text;
}

.form-control[readonly] {
	background-color: #fff;
}

.form-type-line.form-control[readonly] {
	background-color: #fff;
	opacity: 0.7;
}

.form-type-round input.form-control {
	-webkit-border-radius: 10rem;
	-moz-border-radius: 10rem;
	border-radius: 10rem;
	padding-left: 20px;
	padding-right: 20px;
}

.form-type-round .input-group-btn .file-browser {
	-webkit-border-radius: 0 11rem 10rem 0;
	-moz-border-radius: 0 11rem 10rem 0;
	border-radius: 0 11rem 10rem 0;
}

.form-type-line.form-control,
.form-type-line .form-control {
	border-color: transparent;
	padding-left: 0;
	padding-right: 0;
	-webkit-background-size: 0 1px, 100% 1px;
	background-size: 0 1px, 100% 1px;
	background-repeat: no-repeat;
	background-position: center bottom, center calc(100%);
	background-image: -webkit-gradient(linear, left top, left bottom, from(#000), to(#000)),
		-webkit-gradient(linear, left top, left bottom, from(#ebebeb), to(#ebebeb));
	background-image: -webkit-linear-gradient(#000, #000), -webkit-linear-gradient(#ebebeb, #ebebeb);
	background-image: linear-gradient(#000, #000), linear-gradient(#ebebeb, #ebebeb);
	-webkit-transition: background 0.3s;
	transition: background 0.3s;
}

.input-group-btn .file-browser {
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	height: 100%;
}

.dropzone {
	min-height: 150px;
	border: 1px dashed #1ab394;
	padding: 20px 20px;
}

.dz-message {
	display: grid;
	font-size: 20px;
}

.dz-message .note {
	font-size: 13px;
}

.publisher {
	position: relative;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	background-color: #f9fafb;
}

.publisher-input {
	-webkit-box-flex: 1;
	flex-grow: 1;
	border: 0;
	outline: none !important;
	background-color: transparent;
}

.publisher > * {
	margin: 0 5px;
}

.publisher > *:last-child {
	margin-right: 0;
	display: flex;
	overflow: hidden;
}

.publisher-btn {
	background-color: transparent;
	border: 0;
	color: #929daf;
	font-size: 14px;
	cursor: pointer;
	overflow: -moz-hidden-unscrollable;
	-webkit-transition: 0.2s linear;
	transition: 0.2s linear;
}

.publisher-multi {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-align: start;
	align-items: flex-start;
}

.publisher-multi > * {
	margin: 0;
	-webkit-box-flex: 1;
	flex-grow: 1;
	width: 100%;
}

.publisher-multi .publisher-input {
	margin-bottom: 1rem;
}

.flexbox {
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
}

.gap-items > *:first-child {
	margin-left: 0;
}

.gap-items > * {
	margin-left: 5px;
	margin-right: 5px;
}

.form-group label + small {
	padding-left: 10px;
}

.form-group small {
	color: #868e96;
	font-weight: 300;
	font-size: 85%;
	line-height: 1.4;
}

.sidetitle {
	display: block;
	font-style: italic;
	margin-bottom: 10px;
}

.sidetitle::before {
	content: '\2014 \00A0';
}

/**********************************************************************
***********************************************************************
### Tables CSS
*********************************************************************
*********************************************************************/

.table .thead-primary > tr > th,
.table .thead-primary > tr > td,
.table-primary > thead > tr > th,
.table-primary > thead > tr > td {
	background-color: #0866c6;
	color: #ffffff;
}

.table .thead-success > tr > th,
.table .thead-success > tr > td,
.table-success > thead > tr > th,
.table-success > thead > tr > td {
	background-color: #23bf08;
	color: #ffffff;
}

.table .thead-warning > tr > th,
.table .thead-warning > tr > td,
.table-warning > thead > tr > th,
.table-warning > thead > tr > td {
	background-color: #efd31f;
	color: #ffffff;
}

.table .thead-info > tr > th,
.table .thead-info > tr > td,
.table-info > thead > tr > th,
.table-info > thead > tr > td {
	background-color: #45cafc;
	color: #ffffff;
}

.table .thead-danger > tr > th,
.table .thead-danger > tr > td,
.table-danger > thead > tr > th,
.table-danger > thead > tr > td {
	background-color: #f00f00;
	color: #ffffff;
}

/*-------------------------------
11.2 dataTables css
---------------------------------*/

table.dataTable thead th,
table.dataTable thead td {
	border-bottom: 1px solid #dddddd;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
	border-top: 1px solid #dddddd;
}

.ui-widget-header {
	border: 1px solid transparent;
	background: transparent;
}

.dataTables_wrapper .ui-toolbar {
	padding: 0;
}

.dataTables_length label select {
	border: 1px solid #e4e8ea;
	color: #76818a;
}

.dataTables_length label {
	display: inline-block;
	margin-bottom: 0;
	margin-top: 0.85rem;
	color: #76818a;
}

#basicDataTable_filter label,
#noStyleedTable_filter label,
#cellBorder_filter label,
#compactTable_filter label,
#hoverTable_filter label,
#orderActiveTable_filter label,
#scrollableTable_filter label {
	display: inline-block;
	margin-top: 0.5rem;
	color: #76818a;
}

.dataTables_wrapper .dataTables_filter input {
	border: 1px solid #e5e7e8;
	background: #fff;
	color: #646464;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
	border: 1px solid transparent;
	background: transparent;
	color: inherit;
}

.dataTables_wrapper .dataTables_paginate .fg-button {
	padding: 0.3em 0.6em;
	border: 1px solid #e4e5f7;
}

.dataTables_wrapper .dataTables_paginate .fg-button:hover {
	background: #fff;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}

table.dataTable tbody tr th:first-child,
table.dataTabletbody tr td:first-child {
	border-left: 1px solid #ebf2f7;
	border-bottom: 1px solid #ebf2f7;
}

table.dataTable tbody th,
table.dataTable tbody td {
	border-top: 1px solid #ebf2f7;
	border-right: transparent;
	padding: 0.8rem;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
	border-top: 1px solid #ebf2f7;
}

.dataTables_wrapper .dataTables_filter input {
	box-shadow: none !important;
	border: 1px solid #c1c7cd;
	color: #646464;
	border-radius: 4px;
	padding: 5px 12px;
	height: 32px;
}

.dataTables_wrapper .dataTables_filter input:focus {
	outline: 0;
	outline-offset: 0;
}

.footable-page-arrow a,
.footable-page a {
	display: block;
	padding: 2px 8px;
	border: 1px solid #e4e5f7;
	margin: 2px;
}

.footable-page.active a {
	border: 1px solid #fff;
	color: #b8b8b8;
}

.footable.breakpoint > tbody > tr > td > span.footable-toggle {
	color: #001f5f;
}

.footable-odd,
.footable-row-detail {
	background-color: rgba(93, 92, 109, 0.05);
}

.label-table {
	display: inline-block;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	font-size: 10px;
	max-width: 100px;
	padding: 3px;
	text-overflow: ellipsis;
	overflow: hidden;
	vertical-align: top;
	border-radius: 3px;
}

.label-danger {
	border: 1px solid #f44336;
	color: #f44336;
}

.label-dark {
	border: 1px solid #3a444e;
	color: #3a444e;
}

.label-success {
	border: 1px solid #8bc34a;
	color: #8bc34a;
}

/**********************************************************************
***********************************************************************
### Charts CSS
*********************************************************************
*********************************************************************/

.morris-hover.morris-default-style .morris-hover-row-label {
	background-color: #001f5f;
	color: #fff;
	padding: 4px 21px;
	border-radius: 5px 5px 0 0;
	margin: -10px -15px 10px;
}

/**********************************************************************
***********************************************************************
### Maps CSS
*********************************************************************
*********************************************************************/

.jvectormap-container {
	margin-top: 10px;
}

.jvectormap-container svg {
	height: 400px;
}

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
	background: #001f5f;
}

.jvectormap-zoomin,
.jvectormap-zoomout {
	top: 50px;
}

/**********************************************************************
***********************************************************************
### App Views CSS
*********************************************************************
*********************************************************************/

.chat-box-right .chat-body .media {
	margin-top: 20px;
}

.chat-box-right .chat-body .reverse {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.chat-box-right .chat-body .reverse .media-body {
	background: #f4f6fd;
	padding: 15px;
	border-radius: 3px;
}

.fc-toolbar h2 {
	font-size: 20px;
}

.fc-unthemed .fc-content,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-list-heading td,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
	border-color: #edeef1;
}

.fc-prev-button,
.fc-next-button,
.fc-month-button,
.fc-agendaWeek-button,
.fc-agendaDay-button {
	border: 1px solid #ebf2f7;
	background-color: transparent;
	background-image: none !important;
}

.fc-today-button {
	border: 1px solid #e1e4e6;
	background-color: transparent;
	background-image: none !important;
}

.fc-toolbar button .fc-toolbar button:focus,
.fc-toolbar button:active,
.fc-toolbar button:hover {
	z-index: 5;
	box-shadow: none;
	outline: 0;
	border: 1px solid #cfd0d4;
}

.fc-state-active,
.fc-state-down {
	background-color: transparent;
	background-image: none;
	box-shadow: none;
}

.fc-toolbar h2 {
	font-size: 20px;
	color: #4f4f59;
}

.fc .fc-event {
	border: 1px solid transparent;
}

.fc .fc-toolbar > * > * {
	text-transform: uppercase;
}

.fc button {
	text-transform: uppercase;
}

.fc-day-header span,
.fc-day-header a {
	font-weight: normal;
}

.fc button {
	font-size: 10px;
}

.fc .fc-left .fc-button-group > * {
	font-size: 14px;
}

.fc-toolbar .fc-left,
.fc-center,
.fc-toolbar .fc-right {
	margin-top: 5px;
}

.fc-left .fc-today-button,
.fc-right .fc-month-button,
.fc-right .fc-agendaWeek-button,
.fc-right .fc-agendaDay-button {
	height: 3em;
	width: 7em;
	line-height: 3em;
}

.fc-day-grid-event {
	margin: 5px 0;
	padding: 10px 15px;
}

.fc-event,
.fc-event-dot {
	background-color: #628ee7;
}

/**********************************************************************
***********************************************************************
### Pages CSS
*********************************************************************
*********************************************************************/

.user-profile {
	padding: 15px;
}

.user-profile .user-content,
.user-profile .user-email,
.user-profile .user-name {
	color: #fff;
	overflow: hidden;
	white-space: nowrap;
}

.profiletimeline {
	padding-right: 40px;
	padding-left: 0;
	margin: 40px 30px 0 10px;
	border-right: 1px solid #e9ecef;
	border-left: none;
}

html[dir='rtl'] .profiletimeline .sl-left {
	float: right;
	margin-right: -60px;
	margin-left: 15px;
}

.profile-status {
	border: 2px solid #fff;
	border-radius: 50%;
	display: inline-block;
	height: 10px;
	left: 30px;
	position: absolute;
	top: 1px;
	width: 10px;
}

.profiletimeline .sl-left img,
.steamline .sl-left img {
	max-width: 40px;
}

.profiletimeline {
	position: relative;
	padding-left: 40px;
	margin: 40px 10px 0 30px;
	border-left: 1px solid #e9ecef;
}

.profiletimeline .sl-left {
	float: left;
	margin-left: -60px;
	z-index: 1;
	margin-right: 15px;
}

.profiletimeline .sl-item {
	margin-top: 8px;
	margin-bottom: 30px;
}

.profiletimeline .sl-date {
	font-size: 12px;
	color: #a1aab2;
}

.btn:focus,
.btn:active,
button:focus,
button:active {
	outline: none !important;
	box-shadow: none !important;
}

#image-gallery .modal-footer {
	display: block;
}

.thumb {
	margin-top: 15px;
	margin-bottom: 15px;
}

.gallery-container h1 {
	text-align: center;
	margin-top: 70px;
	/* font-family: 'Droid Sans', sans-serif; */
	font-weight: bold;
	color: #58595a;
}

.gallery-container p.page-description {
	text-align: center;
	margin: 30px auto;
	font-size: 14px;
	color: #85878c;
}

.tz-gallery .thumbnail {
	padding-right: 10px;
	margin-bottom: 30px;
	border: none;
}

.tz-gallery .thumbnail:hover {
	-webkit-box-shadow: 0 0 30px #dcdce2;
	-moz-box-shadow: 0 0 30px #dcdce2;
	-o-box-shadow: 0 0 30px #dcdce2;
	box-shadow: 0 0 30px #dcdce2;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.tz-gallery img {
	border-radius: 2px;
}

.tz-gallery .caption {
	padding: 26px 30px;
	text-align: center;
}

.tz-gallery .caption h3 {
	font-size: 14px;
	font-weight: bold;
	margin-top: 0;
}

.tz-gallery .caption p {
	font-size: 12px;
	color: #7b7d7d;
	margin: 0;
}

.baguetteBox-button {
	background-color: transparent !important;
}

.pswipe-gallery {
	width: 100%;
	float: left;
}

.pswipe-gallery img {
	width: 100%;
	height: auto;
}

/*.pswipe-gallery figure {
	display: block;
	float: left;
	margin: 0 5px 5px 0;
	width: 305px;
}
*/

.pswipe-gallery figcaption {
	display: none;
}

.pricingTable1 {
	text-align: center;
	background: #fff;
	margin: 15px auto;
}

.pricingTable1:hover {
	box-shadow: none;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.pricingTable1 > .pricingTable1-header {
	color: #333333;
	padding-top: 40px;
}

.pricingTable1-header > .price-value {
	display: block;
	padding: 25px 0;
	background: #001f5f;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	margin: 0 auto;
	font-size: 24px;
	color: #fff;
	transition: all 0.4s ease 0s;
	font-weight: 700;
}

.price-value > .mo {
	display: block;
	font-size: 12px;
}

.pricingTable1-header > .heading {
	display: block;
	padding-top: 20px;
}

.pricingTable1 > .pricingContent {
	margin-top: 30px;
}

.pricingTable1 > .pricingContent > ul {
	list-style: none;
	padding: 0;
	margin-bottom: 0;
}

.pricingTable1 > .pricingContent > ul > li {
	padding: 10px 0;
	border-top: 1px solid #efefef;
}

.pricingTable1-sign-up {
	padding: 40px 0;
	border-top: 1px solid #efefef;
}

.pricingTable1-sign-up > .btn-block {
	width: 35%;
	margin: 0 auto;
	padding: 8px 0;
	border-radius: 3px;
	background: #001f5f;
	color: #fff;
	text-transform: uppercase;
	font-size: 12px;
	border: 0px none;
	transition: all 0.4s ease 0s;
}

.mid .price-value,
.mid .btn-block {
	background: #ffa000;
}

.pricingTable2 {
	text-align: center;
	background: #fff;
}

.pricingTable2 > .pricingTable2-header {
	color: #333333;
}

.pricingTable2-header > .heading {
	display: block;
	padding-top: 10px;
	position: relative;
}

.heading > h3 {
	text-transform: uppercase;
	font-weight: 700;
	margin-bottom: 15px;
}

.heading > .sale-wrapper {
	height: 88px;
	width: 85px;
	color: #fff;
	overflow: hidden;
	position: absolute;
	right: -4px;
	top: -3px;
}

.sale-wrapper > .sale {
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
	font-size: 12px;
	padding: 8px 0;
	position: absolute;
	left: -10px;
	right: -18px;
	top: 15px;
	text-align: center;
	text-transform: uppercase;
	transform: rotate(45deg);
	width: 130px;
	background: #001f5f;
}

.sale-wrapper > .sale:after {
	content: '';
	position: absolute;
	bottom: -3px;
	right: 6px;
	border-left: 3px solid rgba(0, 0, 0, 0);
	border-right: 3px solid rgba(0, 0, 0, 0);
	border-top: 3px solid #6e8900;
}

.pricingTable2-header > .price-value {
	display: block;
	color: #001f5f;
	font-size: 36px;
	font-weight: 700;
}

.price-value > .mo {
	display: block;
	color: #ffffff;
	font-size: 14px;
	font-weight: normal;
}

.pricingTable2 > .pricingContent {
	margin-top: 20px;
}

.pricingTable2 > .pricingContent > ul {
	list-style: none;
	padding: 0;
	margin-bottom: 0;
}

.pricingTable2 > .pricingContent > ul > li {
	padding: 13px 35px;
	border-top: 1px solid #dcdcdc;
	text-transform: uppercase;
}

.pricingTable2 > .pricingContent > ul > li:nth-child(odd) {
	background: #f3f3f3;
	color: #9c9c9c;
	text-transform: capitalize;
}

.pricingTable2-sign-up {
	padding: 30px 15px;
	border-top: 1px solid #dcdcdc;
	background: #ffffff;
}

.pricingTable2-sign-up .btn-default {
	padding: 10px;
	width: 40%;
	background: #001f5f;
	color: #fff;
	text-transform: capitalize;
	border: 0px none;
	transition: all 0.4s ease 0s;
}

.pricingTable2-sign-up .btn-default:hover {
	background: #2f2f2f;
	color: #fff;
}

.section-pricing {
	z-index: 3;
	position: relative;
}

.section-gray {
	background: #e5e5e5;
}

.block {
	display: inline-block;
	position: relative;
	width: 100%;
	margin-bottom: 30px;
	border-radius: 6px;
	color: rgba(0, 0, 0, 0.87);
	background: #fff;
}

.block-caption {
	color: #3c4857;
}

.block-plain {
	background: transparent;
	box-shadow: none;
}

.block .category {
	font-size: 30px;
	font-weight: bold;
	text-transform: uppercase;
}

.block .category:not([class*='text-']) {
	color: #3c4857;
}

.block-background {
	background-position: center center;
	background-size: cover;
	text-align: center;
}

.block-raised {
	box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12),
		0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.block-background .table {
	position: relative;
	z-index: 2;
	min-height: 280px;
	padding-top: 40px;
	padding-bottom: 40px;
	max-width: 440px;
	margin: 0 auto;
}

.block-background .block-caption {
	color: #ffffff;
	margin-top: 10px;
}

.block-pricing.block-background:after {
	background-color: rgba(0, 0, 0, 0.7);
}

.block-background:after {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	display: block;
	left: 0;
	top: 0;
	content: '';
	background-color: rgba(0, 0, 0, 0.56);
	border-radius: 6px;
}

[class*='pricing-'] {
	padding: 90px 0 60px 0;
}

.block-pricing {
	text-align: center;
}

.block-pricing:hover {
	box-shadow: none;
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	transition: all 0.5s;
}

.block-pricing .block-caption {
	margin-top: 30px;
}

.block-pricing .table {
	padding: 30px !important;
	margin-bottom: 0px;
}

.block-pricing .icon {
	padding: 10px 0 0px;
}

.block-pricing .icon i {
	font-size: 55px;
	border: 1px solid #001f5f;
	color: #001f5f;
	border-radius: 50%;
	width: 130px;
	line-height: 130px;
	height: 130px;
}

.block-pricing h1 small {
	font-size: 14px;
}

.block-pricing h1 small:first-child {
	position: relative;
	top: -17px;
	font-size: 26px;
}

.block-pricing ul {
	list-style: none;
	padding: 0;
	max-width: 250px;
	margin: 10px auto;
}

.block-pricing ul li {
	color: #656d77;
	text-align: center;
	padding: 12px 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.block-pricing ul li:last-child {
	border: 0;
}

.block-pricing ul li b {
	color: #3c4857;
}

.block-pricing ul li i {
	top: 6px;
	position: relative;
}

.block-pricing.block-background ul li,
.block-pricing [class*='table-'] ul li {
	color: #ffffff;
	border-color: rgba(255, 255, 255, 0.3);
}

.block-pricing.block-background ul li b,
.block-pricing [class*='table-'] ul li b {
	color: #ffffff;
}

.block-pricing.block-background [class*='text-'],
.block-pricing [class*='table-'] [class*='text-'] {
	color: #ffffff;
}

.block-pricing.block-background:after {
	background-color: rgba(0, 0, 0, 0.7);
}

.block-background:not(.block-pricing) .btn {
	margin-bottom: 0;
}

.block .table-primary {
	background: linear-gradient(60deg, #ab47bc, #7b1fa2);
}

.block [class*='table-'] .block-caption a,
.block [class*='table-'] .block-caption,
.block [class*='table-'] .icon i {
	color: #ffffff;
}

.block-pricing .block-caption {
	margin-top: 30px;
}

.block [class*='table-'] h1 small,
.block [class*='table-'] h2 small,
.block [class*='table-'] h3 small {
	color: rgba(255, 255, 255, 0.8);
}

.block .table-primary {
	background: #7b1fa2;
}

.block .table-info {
	background: #001f5f;
}

.block .table-success {
	background: #001f5f;
}

.block .table-warning {
	background: #f57c00;
}

.block .table-danger {
	background: #d32f2f;
}

.block .table-rose {
	background: #c2185b;
}

.block [class*='table-'] .category,
.block [class*='table-'] .block-description {
	color: rgba(255, 255, 255, 0.8);
}

/**********************************************************************
***********************************************************************
### Bootstrap Overwrite CSS
*********************************************************************
*********************************************************************/

.btn-primary {
	color: #fff;
	background-color: #001f5f;
	border-color: #001f5f;
}

button:focus,
.btn.focus,
.btn:focus {
	outline: none;
	outline: none;
	box-shadow: none;
}

input,
.input-group-text {
	background-color: #f5f5f5;
}

input,
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control {
	font-size: 13px;
}

input,
.custom-file,
.custom-select,
.form-control {
	font-size: 13px;
	border-radius: 3px;
	box-shadow: none;
	border: 1px solid rgba(0, 0, 0, 0.07);
	transition-duration: 0.5s;
}

input:focus,
.custom-file:focus,
.custom-select:focus,
.form-control:focus {
	border-color: #c7d0e0 !important;
	box-shadow: none !important;
	transition-duration: 0.5s;
}

hr {
	border-top: 1px solid rgba(230, 230, 230, 0.7);
}

pre {
	color: #212529;
	display: block;
	padding: 9.5px;
	margin: 0 0 10px;
	font-size: 13px;
	line-height: 1.42857143;
	word-break: break-all;
	word-wrap: break-word;
	background-color: #f7f7f7;
	border: 1px solid #eceff1;
	border-radius: 2px;
}

.row-xs > div {
	padding-right: 0.625rem;
	padding-left: 0.625rem;
}

.breadcrumb {
	background-color: inherit;
	font-size: 13px;
	font-weight: 400;
}

.breadcrumb .breadcrumb-item {
	text-transform: uppercase;
	font-size: 10px;
}

.breadcrumb-item.active {
	color: #adb5bd;
}

/* .pageheader h1 {
	color: #343a40;
} */

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
	background-color: #001f5f;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
	background-color: #001f5f;
}

.custom-control {
	line-height: 25px;
}

.custom-checkbox .custom-control-label::before {
	border-radius: 0;
}

.input-group-text,
.custom-select {
	font-size: 13px;
}

.input-group-text {
	border: 1px solid #ebf2f7;
	border-right: 0;
}

.table td,
.table th,
.table thead th {
	border-top: 1px solid #ebf2f7;
	vertical-align: middle;
	white-space: nowrap;
	font-weight: 300;
}

.table-bordered td.d-flex {
	border-top: none;
	border-right: none;
}

.table-bordered td,
.table-bordered th {
	border: 1px solid #eceff3;
}

.table thead th {
	border-bottom: 1px solid #ebf2f7;
	color: #12121b;
	font-weight: 500;
}

.table-hover tbody tr:hover {
	background: #f5f6fa;
}

.popover {
	border: 1px solid #ece7e7;
}

.list-group-item {
	border: 1px solid rgba(138, 138, 138, 0.125);
}

.sw-carousel-slider-control {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.sw-carousel-slider-control .carousel-control-prev,
.sw-carousel-slider-control .carousel-control-next {
	background-color: rgb(229, 238, 248);
	position: relative;
	opacity: 1;
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-left: 5px;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

/**********************************************************************
***********************************************************************
### Pace CSS
*********************************************************************
*********************************************************************/

.pace {
	-webkit-pointer-events: none;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.pace-inactive {
	display: none;
}

.pace .pace-progress {
	background: #001f5f;
	position: fixed;
	z-index: 2000;
	top: 0;
	right: 100%;
	width: 100%;
	height: 3px;
}

.pace .pace-progress-inner {
	display: block;
	position: absolute;
	right: 0px;
	width: 100px;
	height: 100%;
	box-shadow: 0 0 10px #001f5f, 0 0 5px #25c9f9;
	opacity: 1;
	-webkit-transform: rotate(3deg) translate(0px, -4px);
	-moz-transform: rotate(3deg) translate(0px, -4px);
	-ms-transform: rotate(3deg) translate(0px, -4px);
	-o-transform: rotate(3deg) translate(0px, -4px);
	transform: rotate(3deg) translate(0px, -4px);
}
.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
	text-align: left !important;
}

/*.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 15px;
  right: 15px;
  width: 14px;
  height: 14px;
  border: solid 2px transparent;
  border-top-color: #4bd5fd;
  border-left-color: #25c9f9;
  border-radius: 10px;
  -webkit-animation: pace-spinner 400ms linear infinite;
  -moz-animation: pace-spinner 400ms linear infinite;
  -ms-animation: pace-spinner 400ms linear infinite;
  -o-animation: pace-spinner 400ms linear infinite;
  animation: pace-spinner 400ms linear infinite;
}
*/

@-webkit-keyframes pace-spinner {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-moz-keyframes pace-spinner {
	0% {
		-moz-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-o-keyframes pace-spinner {
	0% {
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-o-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-ms-keyframes pace-spinner {
	0% {
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes pace-spinner {
	0% {
		transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

/**********************************************************************
***********************************************************************
### HighlightJS CSS
*********************************************************************
*********************************************************************/

.hljs {
	display: block;
	overflow-x: auto;
	padding: 0.5em;
}

.hljs-comment,
.hljs-quote {
	color: #5c6370;
	font-style: italic;
}

.hljs-doctag,
.hljs-keyword,
.hljs-formula {
	color: #c678dd;
}

.hljs-section,
.hljs-name,
.hljs-selector-tag,
.hljs-deletion,
.hljs-subst {
	color: #e06c75;
}

.hljs-literal {
	color: #56b6c2;
}

.hljs-string,
.hljs-regexp,
.hljs-addition,
.hljs-attribute,
.hljs-meta-string {
	color: #98c379;
}

.hljs-built_in,
.hljs-class .hljs-title {
	color: #e6c07b;
}

.hljs-attr,
.hljs-variable,
.hljs-template-variable,
.hljs-type,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-number {
	color: #d19a66;
}

.hljs-symbol,
.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-title {
	color: #61aeee;
}

.hljs-emphasis {
	font-style: italic;
}

.hljs-strong {
	font-weight: bold;
}

.hljs-link {
	text-decoration: underline;
}

/* *********************kyc***************************** */
.upload-title {
	/* font: 500 18px/35px "Roboto", sans-serif; */
	color: #3f4553;
}
.passport h4 {
	/* font: 500 15px/23px "Roboto", sans-serif; */
	color: #5f6771;
	padding: 0;
}
.input-group {
	padding-bottom: 40px;
}
.input-group .upload-file {
	width: 100%;
	height: auto;
}
.input-group .upload-file .upload-file-label {
	/* width: 168px; */
	/* border-radius: 5px; */
	cursor: pointer;
	/* font: 700 14px/40px "Roboto", sans-serif; */
	border: none !important;
	text-align: center;
	transition: all 300ms linear 0s;
	color: #5f6771;
}
.input-group .upload-file.upload-file-label i {
	font-size: 20px;
	padding-right: 10px;
}

.input-group .upload-file-input {
	display: none;
}
.file_added {
	text-align: left;
	padding-left: 190px;
	padding-bottom: 60px;
}
.file_added li {
	/* font: 400 15px/28px "Roboto", sans-serif; */
	color: #5f6771;
}
.ion-paperclip {
	color: #5cb85c !important;
}

/**********************************************************************
***********************************************************************
### Miscellaneous CSS
*********************************************************************
*********************************************************************/

.search-form {
	position: absolute;
	left: 0;
	right: 0;
	border: 1px solid #ecf0f1;
	background: #fff;
	height: 60px;
	z-index: 41;
	margin-top: -100px;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
	-o-transition: all 0.2s;
	transition: all 0.2s;
	font-weight: 400;
	opacity: 0;
}

.search-form input {
	font-size: 13px;
	border: none;
	height: 54px;
	border-radius: 0;
	box-shadow: none;
	padding-left: 25px;
	padding-bottom: 7px;
	color: #3d464c;
	background-color: #fff;
}

.search-form input::placeholder {
	color: #cfd8e7;
}

.search-form #close-search {
	color: #001f5f;
	border: none;
	border-radius: 50%;
	box-shadow: none;
	margin: 10px 15px;
	padding: 0 12px;
	cursor: pointer;
	display: block;
}

.search-form #close-search i {
	font-size: 25px;
	vertical-align: text-top;
}

.search-form input,
.search-form input:focus {
	border: 1px solid transparent !important;
	box-shadow: none;
}

.search-content {
	position: absolute;
	background: #fff;
}

.search-open .search-form {
	margin-top: 0;
	opacity: 1;
}

.clearfix::after {
	display: block;
	clear: both;
	content: '';
}

.tag-item {
	background-color: #d4d5d6;
	display: inline-block;
	font-size: 12px;
	border-radius: 30px;
	height: 20px;
	padding: 0 4px 0 1rem;
	display: inline-flex;
	align-items: center;
	margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
	background-color: white;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: none;
	cursor: pointer;
	font: inherit;
	margin-left: 10px;
	font-weight: bold;
	padding: 0;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
#multiple-email::placeholder {
	color: #9aa8b3;
	opacity: 0.7;
	font-size: 12px;
}

.map-link {
	text-decoration: underline !important;
	font-size: 12px !important;
	color: skyblue !important;
}
.ant-upload-picture-card-wrapper {
	display: block !important;
	width: 100%;
}

@-webkit-keyframes wave {
	5% {
		opacity: 0.6;
	}
	27% {
		-webkit-transform: scale(1.8);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@-moz-keyframes wave {
	5% {
		opacity: 0.6;
	}
	27% {
		-moz-transform: scale(1.8);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@-o-keyframes wave {
	5% {
		opacity: 0.6;
	}
	27% {
		-o-transform: scale(1.8);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes wave {
	5% {
		opacity: 0.6;
	}
	27% {
		-webkit-transform: scale(1.8);
		-moz-transform: scale(1.8);
		-ms-transform: scale(1.8);
		transform: scale(1.8);
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

.wave.in {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.wave.in:after {
	content: '';
	top: -5px;
	left: -5px;
	position: absolute;
	width: 15px;
	height: 15px;
	background: -webkit-radial-gradient(center center, farthest-side circle, rgba(28, 181, 97, 0) 70%, #001f5f 100%);
	background: -moz-radial-gradient(center center, farthest-side circle, rgba(26, 175, 93, 0) 70%, #001f5f 100%);
	background: -o-radial-gradient(center center, farthest-side circle, rgba(26, 175, 93, 0) 70%, #001f5f 100%);
	border: 1px solid #001f5f;
	-webkit-animation: wave 2s 1s infinite linear;
	-moz-animation: wave 2s 1s infinite linear;
	-o-animation: wave 2s 1s infinite linear;
	animation: wave 2s 1s infinite linear;
	zoom: 1;
	filter: alpha(opacity=0);
	-webkit-opacity: 0;
	-moz-opacity: 0;
	opacity: 0;
	-webkit-border-radius: 50%;
	-webkit-background-clip: padding-box;
	-moz-border-radius: 50%;
	-moz-background-clip: padding;
	border-radius: 50%;
	background-clip: padding-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.btn-scroll-top {
	position: fixed;
	bottom: 1.5625rem;
	right: 1.5625rem;
	width: 2.25rem;
	height: 2.25rem;
	border-radius: 0.125rem;
	z-index: 1000;
	background: #001f5f;
	color: #fff;
	text-align: center;
	line-height: 2.25rem;
	text-decoration: none;
}

.btn-scroll-top:hover i {
	color: #fff;
}

.toggle-on.btn-xs,
.toggle-off.btn-xs {
	padding-right: 0.8rem;
	font-size: 12px;
	text-transform: lowercase;
}

.demo-settings-icon {
	border: 1px solid rgba(230, 230, 230, 0.7);
	border-right: none;
	background: #fff;
	padding: 15px 17px 10px;
	z-index: 999;
	position: fixed;
	top: 50%;
	margin-left: -70px;
}

/* avatar upload director */
/* .image-upload-grid{
	border-radius: 50%;
}
.ant-upload-picture-card-wrapper {
    display: flex !important;
    width: 100%;
    justify-content: center;
}
.ant-upload.ant-upload-select-picture-card {
	border-radius: 50% !important
}
.ant-upload-list-picture-card .ant-upload-list-item {
    float: left;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
    border-radius: 50% !important;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
    position: relative;
    height: 100%;
    overflow: hidden;
    border-radius: 50% !important;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye{
	display:none !important
} */

/* Custom Radio */
.cutom_radio {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	top: 38px;
	padding: 0;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	-webkit-font-feature-settings: 'tnum';
	font-feature-settings: 'tnum', 'tnum';
	position: absolute;
	z-index: 1;
	line-height: 1;
	white-space: nowrap;
	vertical-align: sub;
	outline: none;
	cursor: pointer;
}
.cutom_radio-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 0 !important;
	height: 0 !important;
	background-color: #fff;
	border-color: #d9d9d9;
	border-style: solid;
	border-width: 1px;
	border-radius: 100px;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
}

.cutom_radio-inner::after {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	content: '\f058' !important;
	position: absolute;
	top: -15px !important;
	left: 3px !important;
	display: table;
	width: 8px;
	height: 8px;
	color: #5cb85c;
	background-color: transparent !important;
	border-top: 0;
	border-left: 0;
	border-radius: 8px;
	-webkit-transform: scale(0);
	transform: scale(0);
	opacity: 0;
	-webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	content: ' ';
}
.cutom_radio-checked .cutom_radio-inner {
	border-color: transparent !important;
}
.cutom_radio-wrapper:hover .cutom_radio,
.cutom_radio:hover .cutom_radio-inner,
.cutom_radio-input:focus + .cutom_radio-inner {
	border-color: transparent !important;
}
.cutom_radio-checked .cutom_radio-inner::after {
	-webkit-transform: scale(1.8) !important;
	transform: scale(1.8) !important;
}
/* end custom radio */

/* upload docs */
.ant-upload:hover {
	color: #5cb85c !important;
	cursor: pointer !important;
}

.ant-upload.ant-upload-select-picture-card {
	border-radius: 50% !important;
	width: 120px !important;
	height: 120px !important;
}

.ant-pagination {
	margin: 30px !important;
	float: right;
}

/* search map */
.search {
	position: absolute;
	top: 1rem;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 400px;
	z-index: 10;
}

.search input {
	margin-top: 0.5rem;
	padding: 0.5rem;
	font-size: 1rem;
	width: 100%;
}

/**********************************************************************
***********************************************************************
### Responsive CSS
*********************************************************************
*********************************************************************/

@media (min-width: 768px) {
	.page-header-fixed .page-container.container .page-header {
		width: 750px;
	}
}

@media (min-width: 992px) {
	.page-header-fixed .page-container.container .page-header {
		width: 970px;
	}
}

@media (min-width: 1200px) {
	.page-header-fixed .page-container.container .page-header {
		width: 1170px;
	}
}

@media (min-width: 768px) {
	.navbar-nav {
		float: left;
		margin-left: 0;
		margin-right: 0;
		padding: 0;
	}
	.page-header .navbar-nav > li > a > i {
		font-size: 14px;
		vertical-align: text-top;
	}
	.page-header .navbar-nav > li > a > i[class^='icon-'] {
		font-size: 19px;
		vertical-align: top;
	}
}

@media (max-width: 767px) {
	.hidden-xs {
		display: none !important;
	}
}
/* @media (max-width: 800px) {
	.custom-fieldset label {
		width: auto;
		padding: .625rem 1rem;
		background: #fff;
		margin-top: -42px;
		margin-left: -23px !important;
		font-size: 13px;
		display: block;
		float: left;
		text-transform: uppercase;
	}

} */

@media (max-width: 991px) and (min-width: 768px) {
	.hidden-sm {
		display: none !important;
	}
}

@media (max-width: 1199px) and (min-width: 992px) {
	.hidden-md {
		display: none !important;
	}
}

@media (min-width: 1200px) {
	.hidden-lg {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.page-sidebar-collapsed {
		display: block;
	}
	.page-header-fixed .page-header {
		left: 0 !important;
	}
	.page-sidebar {
		position: fixed !important;
		left: -264px;
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}
	.page-inner:not(.login-page) {
		padding: 0px 20px;
	}
	.page-content,
	.page-sidebar-collapsed .page-content {
		width: 100%;
	}
	.page-sidebar-fixed .page-content,
	.page-sidebar-fixed.page-sidebar-collapsed .page-content {
		margin-left: 0;
	}
	.page-header nav.navbar {
		padding: 12px;
		padding-bottom: 0;
	}
	.page-header,
	.page-header .navbar-default .container-fluid,
	.page-header .navbar-default .navbar-collapse {
		padding: 0;
	}
	.navbar li {
		display: inline-block;
		margin: 0px;
	}
	#fixed-sidebar-toggle-button,
	.navbar-nav.navbar-right {
		display: none;
	}
	.navbar-collapse {
		padding: 0 25px !important;
	}
	.navbar-nav {
		float: right;
	}
	.page-sidebar-visible .page-sidebar {
		left: 0;
	}
	#sidebar-toggle-button-close {
		margin-left: auto;
		float: right;
		height: 35px;
		font-size: 25px;
		margin-top: 0px;
		display: block;
	}
	.page-sidebar-collapsed #sidebar-toggle-button-close {
		margin: 0 auto;
		float: none;
	}
}

.demo-infinite-container {
	border: 1px solid #e8e8e8;
	border-radius: 4px;
	overflow: auto;
	padding: 8px 24px;
	/* height: 300px; */
}
.demo-loading-container {
	position: absolute;
	bottom: 40px;
	width: 100%;
	text-align: center;
}
.wallet-card {
	width: 240px;
	padding: 2em;
	border-radius: 10px !important;
	background-color: grey;
}
.active-account {
	background-color: #001f5f !important;
	color: #fff;
}
.remaining-balance {
	color: #fff !important;
}
.current-balance {
	font-size: 32px;
	color: #001f5f;
}
.professional-card {
	border-radius: 20px !important;
}
.add_account {
	position: absolute;
	transform: translate(100%, 100%);
	border: 1px dashed green;
}

@media (width: 320px) {
	.fc-toolbar .fc-left,
	.fc-toolbar .fc-right {
		display: inline-block;
		float: none;
	}
}

@media (min-width: 576px) {
	.form-layout-5 .form-control-label {
		justify-content: flex-end;
	}
}

@media (min-width: 768px) {
	.wizard > .steps > ul li .title,
	.wizard > .steps .current a,
	.wizard > .steps .disabled a,
	.wizard > .steps .done a {
		display: block;
		text-align: center;
		margin-top: 5px;
	}
}

@media screen and (max-width: 990px) {
	.pricingTable21 {
		margin-bottom: 20px;
	}
}

@media (min-width: 576px) {
	.container {
		max-width: 768px;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 960px;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 1024px;
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1280px;
	}
}

@media (max-width: 991.98px) {
	.btn-scroll-top {
		bottom: 0.625rem;
		right: 0.625rem;
		z-index: 9999;
	}
	.btn-scroll-top:focus,
	.btn-scroll-top:hover {
		background: #007aff;
		color: #fff;
		text-decoration: none;
	}
}

@media (max-width: 991.98px) {
	.email-right-sidebar .table td,
	.email-right-sidebar .table th,
	.email-right-sidebar .table thead th {
		white-space: nowrap;
	}
}

@media (min-width: 992px) {
	.navbar-expand-lg .navbar-collapse {
		display: -ms-flexbox !important;
		display: flex !important;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}
	.navbar-expand-lg .navbar-nav {
		-ms-flex-direction: row;
		flex-direction: row;
	}
}
/* Navlinks styling  */

.navbar__link:hover {
	transform: translateY(-2px);
}

.page-sidebar-menu ul li a.navbar__link--active {
	background-color: rgba(5, 88, 183, 0.1);
	border-right: 3px solid #001f5f;
	transition: border-bottom 0.5s ease-in-out;
}
.page-sidebar-menu ul li a.navbar__link--active:hover {
	background-color: rgba(5, 88, 183, 0.1);
	border-right: 3px solid #001f5f;
	transition: border-bottom 0.5s ease-in-out;
}

.rounded-btn {
	border-radius: 20px !important;
	font-size: 12px !important;
}

.pulsate {
	/* background: black; */
	border-radius: 20px;
	margin: 10px;
	height: 50px;
	width: 50px;

	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(96, 51, 199, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(162, 172, 23, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(192, 14, 38, 0);
	}
}

.ant-steps-navigation .ant-steps-item::after {
	margin-top: -12px !important;
	margin-left: -12px !important;
}
.ant-steps-item-title {
	font-size: 14px !important;
}

#account-list.active {
	border-left: 5px solid #001f5f !important;
}

/* appointments */

b {
	/* used for event dates/times */
	margin-right: 3px;
}

.demo-app {
	display: flex;
	min-height: 100%;
	/* font-family: Arial, Helvetica Neue, Helvetica, sans-serif; */
	font-size: 14px;
}

.demo-app-sidebar {
	width: 250px;
	line-height: 1.5;
	background: #eaf9ff;
	border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
	padding: 2em;
}

.demo-app-main {
	flex-grow: 1;
	padding: 3em;
}

.fc {
	/* the calendar root */
	max-width: 1100px;
	margin: 0 auto;
}

/******************* Loader ***********************/

.wrap {
	position: absolute;
	/* top: 50%; */
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.text {
	color: #001f5f;
	display: inline-block;
	margin-left: 10px;
}

.bounceball {
	position: relative;
	display: inline-block;
	height: 37px;
	width: 15px;
}
.bounceball:before {
	position: absolute;
	content: '';
	display: block;
	top: 0;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: #001f5f;
	-webkit-transform-origin: 50%;
	transform-origin: 50%;
	-webkit-animation: bounce 500ms alternate infinite ease;
	animation: bounce 500ms alternate infinite ease;
}
.signup_content {
	position: absolute;
	top: 15%;
}

/* permisssions dropdown(roles) */

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
	display: none !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
	color: #001f5f !important;
}

@-webkit-keyframes bounce {
	0% {
		top: 30px;
		height: 5px;
		border-radius: 60px 60px 20px 20px;
		-webkit-transform: scaleX(2);
		transform: scaleX(2);
	}
	35% {
		height: 15px;
		border-radius: 50%;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
	100% {
		top: 0;
	}
}

@keyframes bounce {
	0% {
		top: 30px;
		height: 5px;
		border-radius: 60px 60px 20px 20px;
		-webkit-transform: scaleX(2);
		transform: scaleX(2);
	}
	35% {
		height: 15px;
		border-radius: 50%;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
	100% {
		top: 0;
	}
}
