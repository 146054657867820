.review {
  width: 100%;
  display: inline-block;
  margin: 10px 0;
}
.review-photo {
  /* height: 80px;
  width: 80px; */
  display: inline-block;
  float: left;
  margin-right: 3em;
}
.review-box {
  float: left;
  display: inline-block;
  width: 80.5%;
  background: #f6f6f6;
  padding: 1.5em;
  position: relative;
}
.review-box:after,
.review-box:before {
  right: 100%;
  top: 10%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.review-box:after {
  border-right-color: #f6f6f6;
  border-width: 25px;
}
.review-comment {
  color: #9a9999;
}
.review-date {
  color: #c7c7c7;
}
.review-author {
  color: #c7c7c7;
}
.review-author strong {
  color: black;
}
