/* override styles */
.ant-divider-horizontal.ant-divider-with-text-left::before {
	border-top: 1px solid #001f5f !important;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
	border-top: 1px solid #001f5f !important;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
	/* color: #fff !important;
background: #001f5f !important;
border-color: #001f5f !important; */
}
.ant-tabs-tab:hover,
.ant-tabs-tab-btn:active {
	color: #001f5f !important;
}
/* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
} */
.recommend-badge {
	position: absolute;
	left: 0;
	top: -5px;
}
