#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#sidebar_logo {
  background: url("../../assets/images/logos/Logo_1.jpeg");
  background-position: top;
  max-width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 14px 0;
}
.ant-layout {
  background: #fff !important;
}
.ant-layout-sider {
  background: #fff !important;
}

.ant-layout-header {
  height: 56px !important;
  line-height: 35px !important;
  background: #f9f9ff !important;
}
.ant-menu-item,
.ant-menu-submenu-title {
  padding: 8px 12px !important;
}

.hideOnMobile {
  display: none;
}
@media only screen and (min-width: 768px) {
  .hideOnMobile {
    display: block;
  }
}

.hideOnDesktop {
  display: block;
}
@media only screen and (min-width: 768px) {
  .hideOnDesktop {
    display: none;
  }
}
