#login-background {
  background-image: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.52),
      rgba(117, 19, 93, 0.73)
    ),
    url("../../assets/images/Loginbg.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.login__card__position {
  position: absolute !important;
  top: 8%;
  right: 10%;
  border-radius: 15px 30px !important;
  max-width: 350px;
}

/* MEDIA QUERIES */

@media only screen and (max-width: 600px) {
  #login-background {
    /* background-image: linear-gradient(to bottom, #fff, #fff), url(""); */
  }
  .card.login__card__position {
    border: none;
    box-shadow: none;
  }
  .login__card__position {
    position: relative !important;
    top: 0;
    right: 0;
    border-radius: 15px 30px;
  }
  .mobile__logo {
    height: 40px;
    width: auto;
  }
}
