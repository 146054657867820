@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon/icomoon.eot");
  src: url("../fonts/icomoon/icomoon.woff") format("woff"),
    url("../fonts/icomoon/icomoon.ttf") format("truetype"),
    url("../fonts/icomoon/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}
html {
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
body {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px;
}

.container-fluid {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
}

/* additional css header */
.header {
  background-color: #ffffff03;
  position: absolute;
  z-index: 104;
  width: 100%;
}
.header__top-bar {
  background-color: #ffffff82;
  padding: 0.9rem;
}
.nav-account .nav-account__item::before {
  content: none;
}
.nav-account .nav-account__item {
  float: left;
  margin-right: 2em;
  font-size: 12px;
}
.nav-account .nav-account__item > a {
  color: #525252;
  /* font-size: 1.2em; */
  font-weight: 500;
}
.header__primary {
  background-color: #0000000a;
  position: relative;
  border: solid 1px rgba(255, 255, 255, 0.23);
}
.header__primary-inner {
  background-color: transparent;
}
.header-logo {
  position: absolute;
  z-index: 3;
  bottom: 0;
  font-size: 2em;
  display: block;
}

.header-logo a {
  display: inline-block;
  height: 45px;
  font-size: 19px;
  font-weight: bold;
  color: white !important;
}

.header-logo a span {
  font-weight: normal;
  opacity: 0.5;
}
.main-nav__list {
  display: inline-block;
  text-align: left;
  padding: 0;
  margin: 0;
  list-style: none;
}
.main-nav__list > li {
  text-align: center;
  display: inline-block;
  padding: 0;
}
.main-nav {
  position: relative;
  text-align: right;
}
.main-nav__list > li > a {
  display: block;
  color: #fff;
  padding: 0 20px;
  line-height: 62px;
  text-decoration: none;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;

  transition: color 0.3s ease;
}
.main-nav__list > li > a:hover {
  color: #fff;
  font-weight: bold;
  background-color: transparent;
  border-bottom: 1px solid #fff;
}

.main-nav .header-mobile__logo {
  border-bottom: 1px solid #ebf3ff;
}

/* additional css header end ************************************ */

.overlay {
  position: absolute;
  background-color: #212529;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
}
header .tophead {
  margin-top: 150px;
}
header .title-main {
  font-size: 50px;
  font-weight: bold;
  text-transform: uppercase;
}
header .subtitle-main {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 35px;
  line-height: 1.5em;
}

header .btn-explore {
  display: inline-block;
  padding: 8px 20px;
  /*width: 128px;*/
  font-size: 14px;
  background: #001f5f;
  border: 1px solid #001f5f;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px #00000059;
}

header .btn-account {
  display: inline-block;
  padding: 8px 20px;
  /* width: 128px;*/
  font-size: 14px;
  background: #ffffff;
  border: 1px solid #ffffff;
  color: #001f5f;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px #00000059;
}

.top-btn-block a {
  margin: 0 7px 0 0;
}

/***overview***/
.overview-wrap .title-overview {
  text-align: center;
  color: #6a6979;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 23px;
}
.overview-wrap .subtitle-overview {
  font-size: 16px;
  font-weight: 300;
  line-height: 27px;
  max-width: 660px;
  margin: 19px auto 30px;
  text-align: center;
}

/***skewed***/
.sesgoabajo {
  z-index: 10;
  position: absolute;
  left: 0;
  bottom: 0;
  border-width: 0 0 15vh 100vw;
  border-style: solid;
  border-color: transparent transparent #ffffff transparent;
}
.sesgoarriba {
  z-index: 10;
  position: absolute;
  left: 0;
  top: 0;
  border-width: 20vh 100vw 0 0;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}
.contenedor {
  overflow: hidden;
}

/***overview***/
.overview-box {
  width: 100%;
  text-align: center;
  /* padding: 35px 20px; */
}

.overview-box h5 {
  font-weight: 700;
  line-height: 30px;
  font-size: 18px;
  text-transform: uppercase;
  color: #606060;
  margin-bottom: 20px;
}
.overview-box p {
  font-size: 14px;
  color: #666666;
}
.features-icons-icon {
  vertical-align: middle;
  margin: 20px auto;
}
.features-icons-icon i {
  color: lightgrey;
}

/***testimonial***/
.testimonials-wrap {
  /* background: linear-gradient(0deg, #1143a6 0%, #001f5f 100%); */
  background: url(https://i.ibb.co/PTJDkgb/testimonials.jpg);
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  padding: 8rem 0 8rem;
}
.title-testimonials {
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 23px;
}
.subtitle-testimonials {
  font-size: 16px;
  font-weight: 300;
  line-height: 27px;
  color: #ffffff;
  max-width: 660px;
  margin: 0px auto 15px;
  text-align: center;
}
.slide-one {
  padding: 30px 30px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px #00000059;
  width: 100%;
  max-width: 350px;
  margin: 1.5em;
  text-align: center;
}
.slick-slide img {
  display: inline-flex !important;
}
.banner-slide img {
  height: 120px;
  width: 120px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
}
.banner-slide h5 {
  color: #046ab3;
  font-weight: 700;
  margin: 15px 0;
}
.banner-slide h6 {
  font-size: 15px;
  color: #000;
  margin-bottom: 45px;
  font-weight: 600;
}
.banner-slide p {
  color: #000;
  font-size: 16px;
}
.slick-prev {
  left: -60px !important;
}
.slick-next {
  right: -60px !important;
}

.slick-prev::before {
  content: "\f0a8" !important;
  font-family: "FontAwesome" !important;
  font-size: 36px !important;
}
.slick-next:before {
  content: "\f0a9" !important;
  font-family: "FontAwesome" !important;
  font-size: 36px !important;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 10px !important;
  line-height: 20px;
  position: absolute;
  top: 30px !important;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: white !important;
}
.heading {
  text-align: center;
  color: #454343;
  font-size: 30px;
  font-weight: 700;
  position: relative;
  margin-bottom: 70px;
  text-transform: uppercase;
  z-index: 999;
}
.white-heading {
  color: #ffffff;
}
.heading:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  height: 40px;
  width: 180px;
  border-radius: 4px;
  transform: translateX(-50%);
  /* background: url(img/heading-line.png); */
  background-repeat: no-repeat;
  background-position: center;
}
.white-heading:after {
  background: url(https://i.ibb.co/d7tSD1R/heading-line-white.png);
  background-repeat: no-repeat;
  background-position: center;
}

.heading span {
  font-size: 18px;
  display: block;
  font-weight: 500;
}
.white-heading span {
  color: #ffffff;
}
/*-----Testimonial-------*/

.testimonial:after {
  position: absolute;
  top: -0 !important;
  left: 0;
  content: " ";
  /* background: url(img/testimonial.bg-top.png); */
  background-size: 100% 100px;
  width: 100%;
  height: 100px;
  float: left;
  z-index: 99;
}

.testimonial {
  min-height: 375px;
  position: relative;
  background: url(https://i.ibb.co/PTJDkgb/testimonials.jpg);
  padding-top: 50px;
  padding-bottom: 50px;
  background-position: center;
  background-size: cover;
}
#testimonial4 .carousel-inner:hover {
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
#testimonial4 .carousel-inner:active {
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
#testimonial4 .carousel-inner .item {
  overflow: hidden;
}

.testimonial4_indicators .carousel-indicators {
  left: 0;
  margin: 0;
  width: 100%;
  font-size: 0;
  height: 20px;
  bottom: 15px;
  padding: 0 5px;
  cursor: e-resize;
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}
.testimonial4_indicators .carousel-indicators li {
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  text-indent: 0;
  margin: 2px 3px;
  cursor: pointer;
  display: inline-block;
  background: #ffffff;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.testimonial4_indicators .carousel-indicators .active {
  padding: 0;
  width: 14px;
  height: 14px;
  border: none;
  margin: 2px 3px;
  background-color: #9dd3af;
  -webkit-border-radius: 100%;
  border-radius: 100%;
}
.testimonial4_indicators .carousel-indicators::-webkit-scrollbar {
  height: 3px;
}
.testimonial4_indicators .carousel-indicators::-webkit-scrollbar-thumb {
  background: #eeeeee;
  -webkit-border-radius: 0;
  border-radius: 0;
}

.testimonial4_control_button .carousel-control {
  top: 175px;
  opacity: 1;
  width: 40px;
  bottom: auto;
  height: 40px;
  font-size: 10px;
  cursor: pointer;
  font-weight: 700;
  overflow: hidden;
  line-height: 38px;
  text-shadow: none;
  text-align: center;
  position: absolute;
  background: transparent;
  border: 2px solid #ffffff;
  text-transform: uppercase;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
  transition: all 0.6s cubic-bezier(0.3, 1, 0, 1);
}
.testimonial4_control_button .carousel-control.left {
  left: 7%;
  top: 50%;
  right: auto;
}
.testimonial4_control_button .carousel-control.right {
  right: 7%;
  top: 50%;
  left: auto;
}
.testimonial4_control_button .carousel-control.left:hover,
.testimonial4_control_button .carousel-control.right:hover {
  color: #000;
  background: #fff;
  border: 2px solid #fff;
}

.testimonial4_header {
  top: 0;
  left: 0;
  bottom: 0;
  width: 550px;
  display: block;
  margin: 30px auto;
  text-align: center;
  position: relative;
}
.testimonial4_header h4 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.testimonial4_slide {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 70%;
  margin: auto;
  padding: 20px;
  position: relative;
  text-align: center;
}
.testimonial4_slide img {
  top: 0;
  left: 0;
  right: 0;
  width: 136px;
  height: 136px;
  margin: auto;
  display: block;
  color: #f2f2f2;
  font-size: 18px;
  line-height: 46px;
  text-align: center;
  position: relative;
  border-radius: 50%;
  box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -o-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
  -webkit-box-shadow: -6px 6px 6px rgba(0, 0, 0, 0.23);
}
.testimonial4_slide p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
  margin: 40px 0 20px 0;
}
.testimonial4_slide h4 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
}

.testimonial .carousel {
  padding-bottom: 50px;
}
.testimonial .carousel-control-next-icon,
.testimonial .carousel-control-prev-icon {
  width: 35px;
  height: 35px;
}

/******/
.row.heading h2 {
  color: #fff;
  font-size: 52.52px;
  line-height: 95px;
  font-weight: 400;
  text-align: center;
  margin: 0 0 40px;
  padding-bottom: 20px;
  text-transform: uppercase;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.our-team .mycard-block {
  float: left;
  width: 100%;
  background: #fff;
  padding: 30px 20px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border: 3px solid #ffffff;
  margin-bottom: 2rem;
}
.our-team .mycard-block:hover {
  border: 3px solid #503eff;
}

.our-team .mycard-block img {
  width: 130px;
  height: 130px;
  border-radius: 100%;
  object-fit: cover;
}
.our-team .mycard-block h3 {
  color: #2a2a2a;
  font-size: 20px;
  font-weight: 500;
  padding: 6px 0;
  text-transform: uppercase;
}
.our-team .mycard-block h3 a {
  text-decoration: none;
  color: #606060;
  font-weight: 700;
}
.our-team .mycard-block h3 a:hover {
  color: #337ab7;
}
.our-team .mycard-block p {
  color: #2a2a2a;
  font-size: 13px;
  line-height: 20px;
  font-weight: 400;
}

.our-team .mycard-block .follow-us li {
  display: inline-block;
  width: auto;
  margin: 0 5px;
}

.btn-circle {
  border-radius: 50%;
}
.my-social-btn {
  background: lightgrey;
}
a.btn.btn-circle.my-social-btn {
  color: #fff;
  height: 40px;
  width: 40px;
}
.mycard-block:hover .my-social-btn.fb {
  background: #205b9f;
}
.mycard-block:hover .my-social-btn.twitter {
  background: #00ace3;
}
.mycard-block:hover .my-social-btn.google {
  background: #ff3921;
}

.fa.fa-eye,
.fa.fa-eye-slash {
  position: relative;
  float: right;
}
.fa-eye-slash:before {
  position: absolute;
  top: -25px;
  right: 10px;
  cursor: pointer;
}
.fa-eye:before {
  position: absolute;
  top: -25px;
  right: 10px;
  cursor: pointer;
}
.choice-icon-image {
  height: 100px;
  width: 81px;
  padding-top: 1.2em;
}

/* KYC SETUP */
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #5cb85c !important;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #5cb85c !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #001f5f !important;
  background: #001f5f !important;
  display: none !important;
}
.ant-steps-vertical .ant-steps-item-icon {
  display: none !important;
}

.ant-btn-primary {
  color: #fff;
  background-color: #001f5f !important;
  border-color: #001f5f !important;
}
.ant-steps-item-icon > .ant-steps-icon {
  position: relative;
  top: -3px !important;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #001f5f !important;
}

.centerdiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Footer */
/* footer.footer{padding:2rem 0;}
footer.footer ul li a{color: #ffffff;text-decoration: none;}
footer.footer p{font-size: 18px;color:#dcdcdc;} */

.footer {
  color: rgb(255, 255, 255);
}
.footer-widgets {
  background-color: #222;
}

.footer-secondary__inner,
.footer-secondary {
  background-color: #222;
}
.footer-copyright {
  color: #000;
}
.social-links__link {
  color: #ffffff;
}
.social-links__link:hover,
.social-links__link:hover .fa {
  color: #ffc107;
}
.info-block__heading {
  font-size: 9px;
  line-height: 1.2em;
  margin-bottom: 5px;
  color: #fff;
  text-transform: uppercase;
}
.footer-logo--has-txt .footer-logo__txt {
  margin-bottom: 0;
  color: #fff;
}
.footer ul li a {
  padding: 0;
}
.kyc-footer {
  position: absolute;
  bottom: 0;
  right: 20px;
}
/* ******************************LOGIN PAGE********************************************************** */

/* register */
ul .check {
  list-style: none;
  padding: 0;
}
.check li {
  padding-left: 1.3em;
}
.check li:before {
  content: "\f00c"; /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
}

.tab-content > .tab-pane {
  display: block;
}

#myTabContent {
  position: relative;
  width: 100%;
  height: 370px;
  z-index: 5;
  overflow: hidden;
}

#myTabContent .tab-pane {
  position: absolute;
  top: 0;
  padding: 60px 100px;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

#login,
.content-3 {
  -webkit-transform: translateX(-250px);
  -moz-transform: translateX(-250px);
  -o-transform: translateX(-250px);
  -ms-transform: translateX(-250px);
  transform: translateX(-250px);
}

#newuser,
.content-4 {
  -webkit-transform: translateX(250px);
  -moz-transform: translateX(250px);
  -o-transform: translateX(250px);
  -ms-transform: translateX(250px);
  transform: translateX(250px);
}

/* .register .register-right ul .nav-item:a.active~#myTabContent #login,
.register .register-right ul .nav-item:a.active~#myTabContent .content-2,
.register .register-right ul .nav-item:a.active~#myTabContent #newuser,
{
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -o-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
    z-index: 100;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
    -webkit-transition: all ease-out 0.2s 0.1s;
    -moz-transition: all ease-out 0.2s 0.1s;
    -o-transition: all ease-out 0.2s 0.1s;
    -ms-transition: all ease-out 0.2s 0.1s;
    transition: all ease-out 0.2s 0.1s;
} */

@keyframes page {
  0% {
    left: 0;
  }
  50% {
    left: 10px;
  }
  100% {
    left: 0;
  }
}

@-moz-keyframes page {
  0% {
    left: 0;
  }
  50% {
    left: 10px;
  }
  100% {
    left: 0;
  }
}

@-webkit-keyframes page {
  0% {
    left: 0;
  }
  50% {
    left: 10px;
  }
  100% {
    left: 0;
  }
}

@-ms-keyframes page {
  0% {
    left: 0;
  }
  50% {
    left: 10px;
  }
  100% {
    left: 0;
  }
}

@-o-keyframes page {
  0% {
    left: 0;
  }
  50% {
    left: 10px;
  }
  100% {
    left: 0;
  }
}

.register {
  background: -webkit-linear-gradient(left, #1143a6, #001f5f) !important;
  margin-top: 3%;
  padding: 3%;
}

.register-left {
  text-align: center;
  color: #fff;
  margin-top: 4%;
}

.register-left input {
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  width: 60%;
  background: #f8f9fa;
  font-weight: bold;
  color: #383d41;
  margin-top: 30%;
  margin-bottom: 3%;
  cursor: pointer;
}

.register-right {
  background: #f8f9fa;
  border-top-left-radius: 15% 50%;
  border-bottom-left-radius: 15% 50%;
}

.register-left img {
  margin-top: 15%;
  margin-bottom: 5%;
  width: 25%;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

.register-left p {
  font-weight: lighter;
  padding: 12%;
  margin-top: -9%;
}

.register .nav-tabs {
  margin-top: 1%;
  border: none;
  background: #001f5f;
  border-radius: 20px;
  width: 35%;
  float: right;
}
#myTab .nav-item {
  padding: 5px 3px;
  text-align: center;
  display: block;
  margin: 0px 6px;
}
.register .nav-tabs .nav-link {
  padding: 10px 8px;
  height: 25px;
  color: #fff;
  font-size: 13px;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.register .nav-tabs .nav-link:hover {
  border: none;
}

.register .nav-tabs .nav-link.active {
  color: #1143a6;
  border: 1px solid #1143a6;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.register-heading {
  text-align: center;
  color: #001f5f;
}

#login.active {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  z-index: 100;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
  -webkit-transition: all ease-out 0.2s 0.1s;
  transition: all ease-out 0.2s 0.1s;
}
#newuser.active {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  z-index: 100;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
  -webkit-transition: all ease-out 0.2s 0.1s;
  transition: all ease-out 0.2s 0.1s;
}

/* *****************************ANOTHER ONE****************************** */
.login-block {
  background: #ffffff; /* fallback for old browsers */
  background: #ffffff; /* Chrome 10-25, Safari 5.1-6 */
  background: #ffffff; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  float: left;
  width: 100%;
  padding: 50px 0;
}
.banner-sec {
  background: linear-gradient(to bottom, #1143a6, #001f5f);
  min-height: 600px !important;
  border-radius: 10px 0px 0px 10px;
  padding: 0;
}
.carousel-inner {
  border-radius: 0 10px 10px 0;
}
.carousel-caption {
  text-align: left;
  left: 5%;
}
.login-sec {
  background: url("../images/mod1.png") no-repeat right top;
  background-size: cover;
  padding: 50px 30px;
  position: relative;
  border-radius: 0px 10px 10px 0px;
}
.login-sec .copy-text {
  position: absolute;
  width: 80%;
  bottom: 20px;
  font-size: 13px;
  text-align: center;
}
.login-sec .copy-text i {
  color: #feb58a;
}
.login-sec .copy-text a {
  color: #e36262;
}
.login-sec h2 {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 30px;
  color: #001f5f;
}
.login-sec h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #001f5f;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}
.btn-login {
  background: #001f5f !important;
  color: #fff !important;
  font-weight: 600;
}
.banner-text {
  width: 70%;
  position: absolute;
  bottom: 40px;
  padding-left: 20px;
}
.banner-text h2 {
  color: #fff;
  font-weight: 600;
}
.banner-text h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #fff;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
}
.banner-text p {
  color: #fff;
}
.login-card {
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 18%);
  padding: 1em;
}
.signup-card {
  max-width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
}

/* ****************login tabs************** */
/*Panel tabs*/
.login-nav-tab {
  margin: auto;
  display: flex;
  justify-content: center;
}
.login-nav-tab .nav-item .nav-link a {
  border-bottom: 1px solid #dee2e6;
}

/* .ant-btn:active, .ant-btn.active .ant-btn:hover, .ant-btn:focus{
    color:#fff !important;
    border-color: #fff !important;

} */

/***responsive---/

@media only screen and (min-width: 960px) and (max-width: 1023px) {}

@media only screen and (min-width: 768px) and (max-width: 959px) {
	.enmica h2{font-size:15px;}
	/******/
.testimonials {
  padding: 18rem 0;
}

@media only screen and (max-width: 736px) and (orientation: landscape) {
}
@media only screen and (max-width: 667px) and (orientation: landscape) {
}
@media only screen and (max-width: 568px) and (orientation: landscape) {
}
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .enmica h2 {
    font-size: 18px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) {
  header .tophead {
    margin-top: 145px;
  }
  header .title-main {
    font-size: 28px;
  }
  header .subtitle-main {
    font-size: 13px;
  }
  header .btn-explore {
    padding: 5px 8px;
    font-size: 13px;
  }
  header .btn-account {
    padding: 5px 8px;
    font-size: 13px;
  }
  /******/
  .overview .title-overview {
    font-size: 28px;
  }
  .overview .subtitle-overview {
    font-size: 14px;
  }
  /******/
  .title-our-team {
    font-size: 28px;
  }
  .subtitle-our-team {
    font-size: 14px;
  }
  /******/
  .title-testimonials {
    font-size: 28px;
  }
  .subtitle-testimonials {
    font-size: 14px;
  }
  /******/
  .slide-one {
    padding: 30px 25px;
  }
  /******/
  section#blog .title-blog {
    font-size: 28px;
  }
  section#blog .subtitle-blog {
    font-size: 14px;
  }
  /******/
  .contactForm {
    padding: 0;
  }
  .contact-wrap h2 {
    font-size: 28px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 479px) {
  header .tophead {
    margin-top: 145px;
  }
  header .title-main {
    font-size: 25px;
  }
  header .subtitle-main {
    font-size: 13px;
  }
  header .btn-explore {
    padding: 5px 8px;
    font-size: 13px;
  }
  header .btn-account {
    padding: 5px 8px;
    font-size: 13px;
  }
  /******/
  .overview .title-overview {
    font-size: 25px;
  }
  .overview .subtitle-overview {
    font-size: 14px;
  }
  /******/
  .title-our-team {
    font-size: 25px;
  }
  .subtitle-our-team {
    font-size: 14px;
  }
  /******/
  .title-testimonials {
    font-size: 25px;
  }
  .subtitle-testimonials {
    font-size: 14px;
  }
  /******/
  .slide-one {
    padding: 30px 25px;
  }
  /******/
  section#blog .title-blog {
    font-size: 25px;
  }
  section#blog .subtitle-blog {
    font-size: 14px;
  }
  /******/
  .contactForm {
    padding: 0;
  }
  .contact-wrap h2 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 320px) {
  header .tophead {
    margin-top: 125px;
  }
  header .title-main {
    font-size: 18px;
  }
  header .subtitle-main {
    font-size: 13px;
  }
  header .btn-explore {
    padding: 5px 8px;
    font-size: 13px;
  }
  header .btn-account {
    padding: 5px 8px;
    font-size: 13px;
  }
  /******/
  .overview .title-overview {
    font-size: 25px;
  }
  .overview .subtitle-overview {
    font-size: 14px;
  }
  /******/
  .title-our-team {
    font-size: 25px;
  }
  .subtitle-our-team {
    font-size: 14px;
  }
  /******/
  .title-testimonials {
    font-size: 25px;
  }
  .subtitle-testimonials {
    font-size: 14px;
  }
  /******/
  .slide-one {
    padding: 30px 25px;
  }
  /******/
  section#blog .title-blog {
    font-size: 25px;
  }
  section#blog .subtitle-blog {
    font-size: 14px;
  }
  /******/
  .contactForm {
    padding: 0;
  }
  .contact-wrap h2 {
    font-size: 25px;
  }
}

#credentials-background {
  background-image: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.52),
      rgba(117, 19, 93, 0.73)
    ),
    url("../images/auth/credentials3.jpg");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  height: 100vh;
}
#reset-background {
  background-image: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0.52),
      rgba(117, 19, 93, 0.73)
    ),
    url("../images/forgot.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

.title-fullstop {
  color: rgba(117, 19, 93, 0.73);
  font-size: 1.5em;
}
