.menu {
  color: #001f5f;
}
.logoWrapper {
  height: 56px;
}
.logo {
  background-image: url("../../assets/images/logos/Logo_1.png");
  background-size: 130px auto;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
}
