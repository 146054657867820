.signup__card__position {
  position: absolute !important;
  top: 8%;
  right: 10%;
  border-radius: 15px 30px !important;
  max-width: 350px;
}

@media only screen and (max-width: 600px) {
  .card.signup__card__position {
    border: none;
    box-shadow: none;
  }

  .signup__card__position {
    position: relative !important;
    top: 0;
    right: 0;
    border-radius: 15px 30px;
  }
}
