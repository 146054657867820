@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');


/* .location-search-input{
  font-family: 'Lato', sans-serif;
  width: 400px;
  height: 40px;
  background: none;
  border: 2px solid black;
  border-radius: 5px;
  font-size: 1rem;
  color: black;
  font-weight: bold;
  margin-top: 300px;
  

} */

.input-suggestion{
  font-family: 'Lato', sans-serif;
  width: 400px;
  height: 60px;
  background-color: white;
  border-bottom: 1px black dotted;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: bold;
  color: #de4228;
  
}
.input-suggestion span{
    color: black
}