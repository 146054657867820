.sidebarChat {
    display: flex;
    padding: 10px 20px 10px 20px;
    cursor: pointer;
    border-bottom: 1px solid rgb(211, 202, 202);
}
.sidebarChat .chat__badge{
    position:absolute;
    right:10px;
    color:#fff
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit{
    color: #fff
}

.sidebarChat:hover {
    background-color: rgba(5,88,183,0.10);
    color: white;
}

.sidebarChat:hover .sidebar__lastMessageName {
    color: black;

}

.sidebarChat:hover .sidebar__lastmessages__color {
    color: red;
}

.sidebarChat h2 {
    font-size: 16px;
    margin-bottom: 0px;
}

.sidebarChat__info {
    margin-left: 15px;
}

.sidebarChat__info__title {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.sidebarChat__wrapper {
    display: flex;

}

.sidebarChat a {

    width: 97%;

}

.sidebarChat__delete {
    display: flex;
    align-items: center;
}

.sidebarChat__delete:hover {
    color: red;
    transform: scale(1.28);
}

a {
    color: black;
    text-decoration: none;

}

.sidebar__lastMessageName {
    color: red;
    /* font-weight:bolder; */
}

.addnew__chat {
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar__lastmessages__color {
    font-weight: 700;
    max-width: 30vw;
    overflow: hidden;
    text-overflow: ellipsis;
    color: gray;
    white-space: nowrap;

}

.room__name:hover {

    color: white;
}

@media(max-width:628px) {
    .sidebar__lastmessages__color {
        max-width: 75vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}