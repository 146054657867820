.hero__image{
    background-image:url('../../assets/images/bg/hero.jpeg');
    background-position: center;
    background-size: cover;
    height: 200px;
}
.profile__content{
    position: relative;
    width: 100%
}
.profile__content .ant-input:hover{
   border-color:#001f5f;
}
.overlap{
position: absolute;
width: 100%;
top: 140px;
margin:0  
}
.profile__image{
    display: flex;
    justify-content: center;
    align-items: center;
    position:relative;
    flex-direction: column;
}
.profile__image img{
    border-radius: 50%;
    height:150px;
    width:150px;
}
.ant-image-mask, .ant-image-mask:hover{
    border-radius: 50%;
}