.footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-top: 1px solid rgba(230, 230, 230, 0.7);
  padding: 0 10px;
  position: fixed;
  bottom: 0;
  min-width: calc(100% - 250px);
}

.footer ul {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.footer ul li {
  position: relative;
  list-style: none;
  width: 20px;
  height: 20px;
  margin: 0px 10px;
}

.footer ul li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex !important;
  background: transparent;
  justify-content: center;
  align-items: center;
  /* color: #fff; */
  font-size: 16px !important;
  transition: 1.5s ease-out;
}

.footer ul li:hover span {
  z-index: 1000;
  transition: 0.6s;
  color: #fff;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.5);
}

.footer ul li:hover span:nth-child(5) {
  transform: translate(40px, -40px);
  opacity: 1;
}

.footer ul li:hover span:nth-child(4) {
  transform: translate(30px, -30px);
  opacity: 0.8;
}

.footer ul li:hover span:nth-child(3) {
  transform: translate(20px, -20px);
  opacity: 0.6;
}

.footer ul li:hover span:nth-child(2) {
  transform: translate(10px, -10px);
  opacity: 0.4;
}

.footer ul li:hover span:nth-child(1) {
  transform: translate(0px, 0px);
  opacity: 0.2;
}

.footer ul li:nth-child(1):hover span {
  background: #1da1f2 !important;
  box-shadow: 0px 5px 12px #1da1f2;
}

.footer ul li:nth-child(2):hover span {
  background: #4267b2 !important;
  box-shadow: 0px 5px 12px #4267b2;
}

.footer ul li:nth-child(3):hover span {
  background: #c13584 !important;
  box-shadow: 0px 5px 12px #c13584;
}

.footer ul li:nth-child(4):hover span {
  background: #0e76a8 !important;
  box-shadow: 0px 5px 12px #0e77a8b9;
}

.footer ul li:nth-child(5):hover span {
  background: #25d366 !important;
  box-shadow: 0px 5px 12px #25d366;
}
